import { type } from "@testing-library/user-event/dist/type";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  RESET_LOGIN_FLAG,
  SOCIAL_LOGIN,
  GET_PROFILE_DATA_VIA_API,
  SUCCESS_GET_PROFILE_DATA,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  LOGOUT_USER,
  LOGIN_USER_ACTIVE_VIA_API,
  UPDATE_USER_VIA_API,
  UPDATE_USER_TYPES_VIA_API,
  UPDATE_USERS_TYPES_VIA_API,
} from "./actionTypes";

export const loginUser = (user, navigate) => {
  // user = {email: 'admin@tektreeinc.com', password: '123456'}
  return {
    type: LOGIN_USER,
    payload: { user, navigate },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};
export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
export const socialLogin = (type, history) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history },
  };
};
export const resetLoginFlag = () => {
  return {
    type: RESET_LOGIN_FLAG,
  };
};
export const getProfileDataViaApi = (email) => ({
  type: GET_PROFILE_DATA_VIA_API,
  payload: { email },
});

export const successGetProfileData = (user) => ({
  type: SUCCESS_GET_PROFILE_DATA,
  payload: user,
});

export const getAllUsers = (action) => ({
  type: GET_ALL_USERS,
  payload:action,
});
export const getAllUsersSuccess = (allAccounts) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: allAccounts,
  };
};
export const logoutUser = (action) => {
  return {
    type: LOGOUT_USER,
    payload:action,
  };
};
export const loginUserActiveViaApi =(action)=>{
  return{
    type:LOGIN_USER_ACTIVE_VIA_API,
    payload:action
  }
}

export const updateUserViaApi=(action)=>{
  return{
    type:UPDATE_USER_VIA_API,
    payload:action
  }
}
export const updateUsersTypeViaApi=(action)=>{
  return{
    type:UPDATE_USERS_TYPES_VIA_API,
    payload:action
  }
}
