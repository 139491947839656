import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Paper,
  Modal,
  Typography,
  TextField,
  Button,
  Link,
  Tooltip,
  Checkbox,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../Theme";

import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  BDMCustomeDateFilter,
  CustomDateFilter,
  FilterHeader,
  JobPostingForm,
  PageHeader,
  Widget,
} from "../../Components";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BusinessIcon from "@mui/icons-material/Business";
import moment from "moment-timezone";
import {
  JobStatusChangeViaApi,
  approvalTransferViaApi,
  clientStatusViaApi,
  getBdmComapniesViaApi,
  transferBdmViaApi,
} from "../../store/companiesList/action";
import { SnackbarComponent } from "../../Components";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { debounce } from "lodash";
import dayjs from "dayjs";
//mport dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CustomSelectThree from "../../Components/Common/CustomSelectThree";
import { ShimmerTable } from "react-shimmer-effects";
import { stopLoadingRedux } from "../../store/reports/action";
import ReactQuill from "react-quill";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {useNavigate } from "react-router-dom";
import {
  jobPostRecruiterViaApi,
  searchjobPostRecruiterViaApi,
} from "../../store/recruiter/action";
import AddIcon from '@mui/icons-material/Add';
import { addIndustriesNamesViaApi, getAllIndustriesViaApi, getAllUsers } from "../../store/actions";
import companyStatus from "../../Components/companyStatusElements/companyStatus";
// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York"); // Set default timezone

const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert("Text copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

const CopyToClipboardButton = ({ text }) => {
  return (
    <Tooltip title="Copy">
      <Link
        variant="body2"
        sx={{ ml: 0.5 }}
        href="javascript:void(0)"
        onClick={(e) => {
          e.preventDefault();
          copyToClipboard(text);
        }}
        color={theme.palette.grey[500]}
      >
        <ContentCopyIcon fontSize="inherit" />
      </Link>
    </Tooltip>
  );
};
const Client = () => {
  const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked,companyOnProcess } = companyStatus;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchResultsData =
    useSelector((state) => state.Search.globalSearchData) || [];
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData);
  const client=storeData.clientDb
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const [searchClientJobPosition, setSearchClientJobPosition] = useState("");
  const [searchClientCompany, setSearchClientCompany] = useState("");
  const [searchClientStatus, setSearchClientStatus] = useState([]);
  const [searchClientJobLocation, setSearchClientJobLocation] = useState("");
  const industries = useSelector((state) => state.Search.industries || []);

  const industryNames = industries.map((item) => item.industryName) || [];
  let companiesList = useSelector((state) => state.CompaniesList?.BDMCompanies);
  companiesList =
    companiesList &&
    companiesList.filter(
      (company) =>
        company.status !== "Active" &&
        company.status !== companyDNC &&
        company.status !== companyBlocked
    );
  const [totalCompaniesList, setTotalCompaniesList] = useState(
    companiesList || []
  );
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllIndustriesViaApi(action));
    dispatch(getAllUsers(action))
  }, [dispatch]);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState("");

  const [searchToDate, setSearchToDate] = useState("");
  const transformData = (data) => {
    let count = 1;
    // Map over the filtered data to transform each item
    const transformed = data.map((item, idx) => {
      const job =
        item.jobDetails && item.jobDetails.length > 0 ? item.jobDetails[0] : {};
      return {
        id: item._id, // Use item _id as id
        ID: count++,
        companyName: item.companyName || "",
        jobTitle: job.jobTitle || "",
        jobLocation: job.jobLocation || "",
        jobDescription: job.jobDescription || "",
        jobWebsite:job.jobWebsite ||"",
        status:
          job.status === "Active"
            ? "Positive"
            : (job.status === "Close" ||
                job.status === "Hold" ||
                job.status === "Backout" ||
                item.transFerBdm) &&
              job.approvalStatus !== true
            ? "Pending for approval"
            : job.status, // Manually set to "Open"
      };
    });

    return transformed;
  };

  // State to hold transformed data
  const [simplifiedData, setSimplifiedData] = useState([]);
  const filterSearchData = Array.isArray(simplifiedData)
    ? simplifiedData.filter((data) => {
        const lowerCaseCompanyName = data.companyName?.toLowerCase() || "";
        const lowerCaseJobPosition = data.jobTitle?.toLowerCase() || "";
        const lowerCaseJobLocation = data.jobLocation?.toLowerCase() || "";

        const statusType = data.status || "";

        const lowerCaseSearchJobPosition =
          searchClientJobPosition?.toLowerCase() || "";
        const lowerCaseSearchCompany = searchClientCompany?.toLowerCase() || "";
        const lowerCaseSearchJobLocation =
          searchClientJobLocation?.toLowerCase() || "";
        const SearchStatus = searchClientStatus || [];

        return (
          lowerCaseJobPosition.includes(lowerCaseSearchJobPosition) &&
          lowerCaseCompanyName.includes(lowerCaseSearchCompany) &&
          lowerCaseJobLocation.includes(lowerCaseSearchJobLocation) &&
          (SearchStatus.length === 0 || SearchStatus.includes(statusType))
        );
      })
    : [];
  // Effect to transform data on component mount
  useEffect(() => {
    if (companiesList && companiesList.length > 0) {
      const transformed = transformData(companiesList);

      setSimplifiedData(transformed);
    } else if (companiesList && companiesList.length === 0) {
      setSimplifiedData([]);
    }
  }, [companiesList]);

  const sortedFilteredResults =
    searchResultsData.length > 0
      ? searchResultsData.sort(
          (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
        )
      : [];
  const [searchEmail, setSearchEmail] = useState("");

  const handleChangeStatusModal = () => {
    const isValidEmail = (email) => {
      // This regex allows a wide range of email formats while ensuring basic structure validity
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    };

    // Trim and lowercase the searchEmail input
    const trimmedEmail = searchEmail.trim().toLowerCase();
    // Check if searchEmail is a valid email format
    if (!isValidEmail(trimmedEmail)) {
      enqueueSnackbar(`Please enter a valid email address`, {
        variant: "warning",
      });
      return;
    }
    const action = {
      addedBy: storeData._id,
      addedOn: new Date(),
      status: companyOnProcess,
      searchEmail,
      client:client
    };
    dispatch(clientStatusViaApi(action));
    const today = dayjs().tz("America/New_York").startOf("day");
    handleDateChange([today, today.endOf("day")]);
  };
  const handleKeyPress = (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      if (searchEmail.length === 0) {
        // If reduxPlaceOrder is not empty, return or show a message
        return;
      }
      handleChangeStatusModal();
    }
  };
  const formatDate = (dateString) => {
    const date = moment.tz(dateString, "UTC"); // Parse date in UTC
    return date.tz("America/New_York").format("MMMM DD, YYYY"); // Convert to Kolkata time and format
  };

  const calculateDays = (providedDate) => {
    const timeZone = "America/New_York";

    // Convert provided date to IST
    const providedDateObj = moment.tz(providedDate, timeZone).startOf("day");

    // Get today's date in IST
    const todayDateObj = moment.tz(timeZone).startOf("day");

    // Calculate the difference in milliseconds between the provided date and today's date
    const differenceInMilliseconds =
      todayDateObj.valueOf() - providedDateObj.valueOf();

    // Convert milliseconds to days
    const daysDifference = Math.round(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return daysDifference;
  };
  // const showSearchData = sortedFilteredResults.filter((data) =>
  //   data.contactDetails.some(
  //     (contact) =>
  //       contact.emailId.trim().toLowerCase() ===
  //       searchEmail.trim().toLowerCase()
  //   )
  // );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const [closeReason, setCloseReason] = useState("");
  const [currentCompanyJobStatus, setCurrentCompanyJobStatus] = useState("");

  const [transferModal, setTransferModal] = useState(false);
  const [currentTransferId, setCurrentTransferId] = useState(null);
  const [transformReason, setcurrentTransferId] = useState("");
  const [selectBdm, setSelectBdm] = useState("");

  ///
  const quillRef = useRef(null);

  const handleStatusChange = (id, newStatus) => {
    if (
      newStatus === "Close" ||
      newStatus === "Hold" ||
      newStatus === "Backout"
    ) {
      setIsModalOpen(true);
      setCurrentCompanyId(id);
      setCurrentCompanyJobStatus(newStatus);
    } else if (newStatus === "Transfer") {
      setTransferModal(true);
      setCurrentTransferId(id);
      setCurrentCompanyJobStatus(newStatus);
    } else {
      const updatedCompanyList = simplifiedData.map((company) =>
        company.id === id ? { ...company, status: newStatus } : company
      );
      const companyToUpdate = companiesList.find(
        (company) => company._id === id
      );

      if (companyToUpdate) {
        // Update jobDetails of the found company
        const updatedJobDetails = companyToUpdate.jobDetails.map((job) => ({
          ...job,
          status: newStatus,
        }));
        // Dispatch API call with updated jobDetails
        const action = {
          id: companyToUpdate._id,
          jobDetails: updatedJobDetails,
          client:client,
        };
        dispatch(JobStatusChangeViaApi(action));
        const today = dayjs().tz("America/New_York").startOf("day");

        handleDateChange([today, today.endOf("day")]);
      }

      setSimplifiedData(updatedCompanyList);
    }
  };
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "DNC", isChecked: false },
    { id: 2, label: "Blocked", isChecked: false },
  ]);

  // Handler for checkbox change
  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isChecked: checkbox.id === id,
    }));
    setCheckboxes(updatedCheckboxes);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCloseReason("");
  };
  const handleTransferModalClose = () => {
    setTransferModal(false);
    setcurrentTransferId("");
    setCurrentTransferId(null);
    setSelectBdm("");
  };
  const handleModalConfirm = () => {
    const updatedCompanyList = simplifiedData.map((company) =>
      company.id === currentCompanyId
        ? { ...company, status: currentCompanyJobStatus }
        : company
    );

    const companyToUpdate = companiesList.find(
      (company) => company._id === currentCompanyId
    );

    if (companyToUpdate) {
      // Update jobDetails of the found company
      const updatedJobDetails = companyToUpdate.jobDetails.map((job) => ({
        ...job,
        status: currentCompanyJobStatus,
        statusAdvice:
          checkboxes.find((checkbox) => checkbox.isChecked)?.label || "",
        closeReason: closeReason,
        approvalStatus: false,
      }));
      // Dispatch API call with updated jobDetails
      const action = {
        id: companyToUpdate._id,
        jobDetails: updatedJobDetails,
        bdmRequestDate: new Date(),
        client:client,
      };

      dispatch(JobStatusChangeViaApi(action));
      const today = dayjs().tz("America/New_York").startOf("day");

      handleDateChange([today, today.endOf("day")]);
    }

    setSimplifiedData(updatedCompanyList);
    handleModalClose();
  };
  const handleModalTransFerConfirm = () => {
    const updatedCompanyList = simplifiedData.map((company) =>
      company.id === currentCompanyId
        ? { ...company, status: currentCompanyJobStatus }
        : company
    );

    const companyToUpdate = companiesList.find(
      (company) => company._id === currentTransferId
    );
    let transFerBdm = [];
    if (companyToUpdate) {
      if (companyToUpdate.transFerBdm) {
        transFerBdm.push(...companyToUpdate.transFerBdm, {
          oldBdm: companyToUpdate.addedBy,
          newBdm: selectBdm,
          reason: transformReason,
          transFerDateTime: new Date(),
        });
      } else {
        transFerBdm.push({
          oldBdm: companyToUpdate.addedBy,
          newBdm: selectBdm,
          reason: transformReason,
          transFerDateTime: new Date(),
        });
      }
      const updatedJobDetails = companyToUpdate.jobDetails.map((job) => ({
        ...job,
        status: job.status,
        statusAdvice: "",
        closeReason: transformReason,
        approvalStatus: false,
      }));
      const action = {
        id: companyToUpdate._id,
        transFerBdm,
        jobDetails: updatedJobDetails,
        bdmRequestDate: new Date(),
        client:client,
      };
      dispatch(approvalTransferViaApi(action));
      const today = dayjs().tz("America/New_York").startOf("day");

      handleDateChange([today, today.endOf("day")]);
    }
    setSimplifiedData(updatedCompanyList);
    handleTransferModalClose();
  };
 
  function generateJobString(job) {
    // Remove spaces, truncate the company name to the first 2 characters, and convert to uppercase
    const companyNamePart =
      job?.companyName?.replace(/\s+/g, "").substring(0, 2).toUpperCase() || "";
    const BDMNamePart =
      storeData?.userSudoFirstName
        .replace(/\s+/g, "")
        .substring(0, 2)
        .toUpperCase() || "";
    // Get the current date in the format 'DD-MM-YY'
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).substring(2); // Get the last two digits of the year
    const formattedDate = `${day}${month}${year}`;
    // Get the current timestamp
    const timestamp = Date.now();

    // Combine parts into the desired format with no spaces
    return `${BDMNamePart}${companyNamePart}-${formattedDate}-${timestamp}`;
  }

  const [selectDocument, setSelectDocument] = useState("");
  const defaultJobDetails = {
    jobCode: "",
    jobTitle: "",
    jobWebsite:"",
    clientBillRateSalary: {
      currency: "USD",
      amount: "",
      paySchedule: "",
      jobType: "",
    },
    payRateSalary: {
      currency: "USD",
      minAmount: "",
      maxAmount: "",
      paySchedule: "",
      jobType: "",
    },
    jobStartDate: new Date(),
    jobEndDate: null,
    respondBy: "",
    remoteJob: "",
    expensesPaid: "",
    country: "USA",
    states: "",
    location: "",
    jobStatus: "Active",
    jobType: "",
    requiredHourWeek: "",
    client: "",
    clientManager: "",
    endClient: "",
    clientJobID: "",
    requiredDocuments: [],
    turnoutTime: {
      time: "",
      dates: "",
    },
    priority: "",
    clientCategory: "",
    duration: "",
    additionalDetails: "",
    areaCode: "",
    workAuthorization: [],
    interviewMode: "",
    applicationForm: "",
    clearance: "",
    address: "",
    employmentTestTemplate: "",
    employmentLevel: "",
  };
  const defaultSkills = {
    industry: "",
    degree: "",
    experience: {
      min: "",
      max: "",
    },
    evaluationTemplate: "",
    primarySkills: [],
    secondarySkills: "",
    languages: [],
  };
  const defaultOrganizationalInformation = {
    numberofPositions: "",
    maximumAllowedSubmission: "",
    taxTerms: [],
    salesManager: "",
    department: "",
    recruitmentManager: "",
    assignedTo: "",
    primaryRecruiter: "",
    comments: "",
    additionalNotifications: "",
    careerPortalPublishedDate: null,
    jobDescription: "",
  };
  const [jobDetails, setJobDetails] = useState(defaultJobDetails);
  const [skills, setSkills] = useState(defaultSkills);
  const [assignedTo,setAssignedTo]=useState('')
  const [organizationalInformation, setOrganizationalInformation] = useState(
    defaultOrganizationalInformation
  );
  const [jobPostStatus, setJobPostStatus] = useState("");
  const [jobPostingId, setJobPostingId] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (
      JobPosting &&
      JobPosting.length > 0 &&
      selectDocument &&
      selectDocument.id
    ) {
      const findExactDocument = JobPosting.find(
        (job) => job.bdmCompanyId === selectDocument.id
      );
      if (findExactDocument) {
        setSkills(findExactDocument.skills || defaultSkills);
        setJobDetails(findExactDocument.jobDetails || defaultJobDetails);
        setOrganizationalInformation(
          findExactDocument.organizationalInformation ||
            defaultOrganizationalInformation
        );
        setJobPostStatus(findExactDocument.status);
        setJobPostingId(findExactDocument._id);
      }
    }
  }, [JobPosting, selectDocument]);
  const [isJobPostingModalOpen, setIsJobPostingModalOpen] = useState(false);
  const handleJobPostingModal = (row) => {
    const actions={
      id:row.id,
      client:client
    }
    dispatch(searchjobPostRecruiterViaApi(actions));
    setSelectDocument(row);

    if (!jobDetails.jobCode) {
      setJobDetails((job) => ({
        ...job,
        jobCode: generateJobString(row) || "", // Use 'row' directly to generate the job string
      }));
    }
    if (!jobDetails.jobTitle) {
      setJobDetails((job) => ({
        ...job,
        jobTitle: row.jobTitle || "",
      }));
    }
    if (!jobDetails.jobWebsite) {
      setJobDetails((job) => ({
        ...job,
        jobWebsite: row.jobWebsite || "",
      }));
    }
    setOrganizationalInformation((organation)=>({
      ...organation,
      jobDescription:row.jobDescription||""
    }))

    const jobLocation = row?.jobLocation || "";
    // const [city = "", state = ""] = jobLocation.split(", ");
    const [city, state] = jobLocation.includes(",") 
  ? jobLocation.split(",").map(str => str.trim()) 
  : [jobLocation.trim(), jobLocation.trim()];
    const companyName = row?.companyName || "";
    setJobDetails((job) => ({
      ...job,
      states: state, // Note: "states" was used instead of "state"
      location: city,
      client: companyName,
    }));

    setIsJobPostingModalOpen(!isJobPostingModalOpen);
  };
  const JobPostingDataFilter=JobPosting
  const handleViewJobPost=(row)=>{
    const actions={
      id:row.id,
      client:client
    }
dispatch(searchjobPostRecruiterViaApi(actions));
const action={
  companyName:row.companyName,
  id:row.id,
  page:'client'

}

if (action) {
  navigate("/jobPostDetails", { state: { company: action } });
}

  }

  const handleJobPostingCloseModal = () => {
    setJobDetails(defaultJobDetails);
    setSkills(defaultSkills);
    setOrganizationalInformation(defaultOrganizationalInformation);
    setIsJobPostingModalOpen(!isJobPostingModalOpen);
    setErrors({});
    setAssignedTo('')
  };


const trimFields = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
     if (Array.isArray(value)) {
      acc[key] = value; 
    }
     else if (typeof value === "string") {
      acc[key] = value.trim(); // Trim strings
    } else if (typeof value === "object" && value !== null) {
      acc[key] = trimFields(value); // Recursively trim nested objects
    } else {
      acc[key] = value; // Handle other types as-is
    }
    return acc;
  }, {});
};
  const handleJobPostingSaveDraft = debounce(() => {
    const action = {
      addedBy: storeData._id,
      addedOn: new Date(),
      jobDetails: jobDetails,
      skills: skills,
      organizationalInformation: organizationalInformation,
      bdmCompanyId: selectDocument.id,
      status: "Draft",
      assignedTo:assignedTo,
      id: jobPostingId,
      industrieName:skills.industry,
      client:client,
    };
    dispatch(jobPostRecruiterViaApi(action));
    if(action.industrieName){
      dispatch(addIndustriesNamesViaApi(action))
    }
    handleJobPostingCloseModal();
  }, 1000);

  const validateForm = () => {
    const newErrors = {};

    // Check if jobCode is present and not empty inside jobDetails
    if (!jobDetails?.jobCode) {
      newErrors.jobCode = "Job Code is required in Job Details";
    }

    // Check if jobTitle is empty
    if (!jobDetails?.jobTitle) {
      newErrors.jobTitle = "Job Title is required";
    }
   
    // Validate country
    if (!jobDetails?.country) {
      newErrors.country = "Country is required";
    }

    // Validate remoteJob
    if (!jobDetails?.remoteJob) {
      newErrors.remoteJob = "Remote Job status is required";
    }

    // Validate location
    if (!jobDetails?.location) {
      newErrors.location = "Location is required";
    }
    if (!jobDetails?.jobStatus) {
      newErrors.jobStatus = "jobStatus is required";
    }

    // Validate duration
    if (jobDetails.jobType!=="Full Time"&&!jobDetails?.duration) {
      newErrors.duration = "Duration is required";
    }
    if (!skills?.experience?.min) {
      newErrors.skillsExperienceMin = "min is required";
    }
    if (!skills?.experience?.max) {
      newErrors.skillsExperienceMax = "Max is required";
    }
    // Validate primarySkills
    if (!skills?.primarySkills || skills.primarySkills.length === 0) {
      newErrors.primarySkills = "At least one primary skill is required";
    }

    if (!organizationalInformation?.numberofPositions) {
      newErrors.numberofPositions = "NumberofPositions is required";
    }
    if (
      !organizationalInformation?.taxTerms ||
      organizationalInformation.taxTerms.length === 0
    ) {
      newErrors.taxTerms = "NumberofPositions is required";
    }

    setErrors(newErrors);

    // Returns true if no errors exist
    return Object.keys(newErrors).length === 0;
  };


  const handleJobPostingSaveSubmit = debounce(() => {
    if (validateForm()) {
      // Trim spaces from jobDetails, skills, and organizationalInformation
    const trimmedJobDetails = trimFields(jobDetails);
    const trimmedSkills = trimFields(skills);
    const trimmedOrganizationalInformation = trimFields(organizationalInformation);

      const action = {
        addedBy: storeData._id,
        addedOn: new Date(),
        jobDetails: trimmedJobDetails,
        skills: trimmedSkills,
        organizationalInformation: trimmedOrganizationalInformation,
        bdmCompanyId: selectDocument.id,
        assignedTo:assignedTo,
        status: "Submit",
        id: jobPostingId,
        industrieName:skills.industry,
        client:client,
      };
      dispatch(jobPostRecruiterViaApi(action));
      if(action.industrieName){
        dispatch(addIndustriesNamesViaApi(action))
      }
      const today = dayjs().tz("America/New_York").startOf("day");

      handleDateChange([today, today.endOf("day")]);
      handleJobPostingCloseModal();
    }
  }, 1000);

  const companyColumn = [
    {
      field: "ID",
      headerName: "ID",
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type={"line"}
          setSearchClientCompany={setSearchClientCompany}
          selectedValue={searchClientCompany}
          title={"clientCompanyName"}
        />
      ),
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      minWidth: 150,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type={"line"}
          setSearchClientJobPosition={setSearchClientJobPosition}
          selectedValue={searchClientJobPosition}
          title={"clientJobPosition"}
        />
      ),
    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type={"line"}
          setSearchClientJobLocation={setSearchClientJobLocation}
          selectedValue={searchClientJobLocation}
          title={"clientJobLocation"}
        />
      ),
    },
    {
      field: "jobDescription",
      headerName: "Job Description",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box>
          <Link
            href={params.value}
            target="_blank"
            variant="subtitle2"
            underline="hover"
          >
            Open Job Description
          </Link>
          <CopyToClipboardButton text={params.value} />
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Positive",
        "Close",
        "Hold",
        "Backout",
        "Interview L1",
        "Interview L2",
        "Interview L3",
        "Priority",
        "Placed",
        "Agreement",
        "Transfer",
      ],
      renderCell: (params) => {
        return (
          <Box>
            <Chip
              label={params.value}
              size="small"
              sx={{ px: 0.5 }}
              color={
                params.value === "Positive"
                  ? "success"
                  : params.value === "Open"
                  ? "success"
                  : params.value === "Close"
                  ? "error"
                  : params.value === "Hold"
                  ? "warning"
                  : params.value === "Interview L1"
                  ? "info"
                  : params.value === "Interview L2"
                  ? "info"
                  : params.value === "Interview L3"
                  ? "info"
                  : params.value === "Priority"
                  ? "secondary"
                  : "default"
              }
            />
          </Box>
        );
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type={"multiselect"}
          setSearchClientStatus={setSearchClientStatus}
          selectedValue={searchClientStatus}
          title={"clientStatus"}
          options={[
            'Positive',
            "Open",
            "Close",
            "Blocked",
            "Hold",
            "Interview L1",
            "Interview L2",
            "Interview L3",
            "Pending for approval",
          ]}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            justifyContent: "space-around",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.row.status === "Positive" && (
            <Tooltip title="JobPost">
              <IconButton
                 size="small"
                color="warning"
                onClick={() => handleJobPostingModal(params.row)}
              >
                <AddIcon fontSize="inherit" color="primary" />
              </IconButton>
            </Tooltip>
          )}
           {params.row.status === "Open" && (
            <Tooltip title="Preview">
               <IconButton
              size="small"
              color="warning"
              onClick={() => handleViewJobPost(params.row)}
            >
              <VisibilityIcon fontSize="12" />
            </IconButton>
               </Tooltip>
           )}
        </Box>
      ),
    },
  ];

  const handleDateChange = debounce((selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    setLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);
      const startDate = new Date(formattedDates[0]);
      const endDate = new Date(formattedDates[1]);
      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        userId: storeData._id,
        client:client
      };
      dispatch(getBdmComapniesViaApi(action));
    }
  }, 1000);
  useEffect(() => {
    const today = dayjs().startOf("day");

    handleDateChange([
      dayjs().tz("America/New_York").startOf("day"),
      dayjs().tz("America/New_York").endOf("day"),
    ]);
  }, []);
  let countPositive = 0;
  let countOpen = 0;
  let countClose = 0;
  let countHold = 0;
  let countInterviews = 0;
  companiesList &&
    companiesList.map((companies) => {
      companies.jobDetails &&
        companies.jobDetails.map((jobDeatils) => {
          if (jobDeatils.status === "Open") {
            countOpen++;
          } else if (
            jobDeatils.status === "Close" &&
            jobDeatils.approvalStatus === true
          ) {
            countClose++;
          } else if (
            jobDeatils.status === "Hold" &&
            jobDeatils.approvalStatus === true
          ) {
            countHold++;
          } else if (
            jobDeatils.status === "Interview L1" ||
            jobDeatils.status === "Interview L2" ||
            jobDeatils.status === "Interview L3"
          ) {
            countInterviews++;
          } else if (
            jobDeatils.status === "Active" ||
            jobDeatils.status === "Positive"
          ) {
            countPositive++;
          }
        });
    });

  const takeOnlyBdmData = allUsers.filter((user) => user.userType.includes("BDM"));
  const optionData = [...takeOnlyBdmData];
  const handleOptionChange = (title, data) => {
    setSelectBdm(data);
  };

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"MyCompaniesList"}>
          <Box sx={{ mb: 2 }}>
            <BDMCustomeDateFilter handleDateChange={handleDateChange} />
          </Box>

          <Box>
            <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 15 }}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget
                  title="Open Positive"
                  value={countPositive}
                  icon={<BusinessIcon />}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget
                  title="Open Jobs"
                  value={countOpen}
                  icon={<BusinessIcon />}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget
                  title="Closed Jobs"
                  value={countClose}
                  icon={<BusinessIcon />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget
                  title="Hold Jobs"
                  value={countHold}
                  icon={<BusinessIcon />}
                  color="warning"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget
                  title="Interview"
                  value={countInterviews}
                  icon={<BusinessIcon />}
                  color="info"
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Paper>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 3, mb: 2 }}
              >
                <Box sx={{ maxWidth: 600, display: "flex", flex: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      label="Email"
                      onChange={(e) => setSearchEmail(e.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<TrendingUpIcon />}
                    onClick={() => handleChangeStatusModal(companyOnProcess)}
                  >
                    Get Job Details
                  </Button>
                </Box>
              </Box>
              {loading ? (
                <>
                  <ShimmerTable row={5} col={5} />
                </>
              ) : (
                <>
                  <Box p={2}>
                    <DataGrid
                      rows={filterSearchData}
                      columns={companyColumn}
                      // processRowUpdate={(newRow) => {
                      //   handleStatusChange(newRow.id, newRow.status);
                      //   return newRow;
                      // }}
                      processRowUpdate={(newRow, oldRow) => {
                        if (newRow.status !== oldRow.status) {
                          handleStatusChange(newRow.id, newRow.status);
                        }
                        return newRow;
                      }}
                      disableColumnMenu
                      disableColumnSorting
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnReorder
                      disableColumnResize
                      disableRowSelectionOnClick
                      disableColumnSort
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 100 },
                        },
                      }}
                      pageSizeOptions={[100]}
                      getRowHeight={() => "auto"}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-cell": {
                          display: "flex",
                          alignItems: "center !important",
                        },
                        [`& .MuiDataGrid-checkboxInput`]: {
                          color: theme.palette.primary.main,
                          p: 0,
                          "&.Mui-checked": {
                            color: theme.palette.primary.dark,
                          },
                        },
                        [`& .MuiDataGrid-scrollbar`]: {
                          scrollbarWidth: "thin",
                          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                        },
                        [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                          borderRadius: "10px",
                        },
                        [`& .MuiDataGrid-scrollbar:hover`]: {
                          scrollbarColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    />
                  </Box>
                </>
              )}
            </Paper>
          </Box>
          <SnackbarComponent />
        </LayoutSidebarMenu>

        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reason for Closing
            </Typography>
            
            <Box
              sx={{
                maxHeight: 200,
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px", // width of the scrollbar
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.primary[400],
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: 5,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={closeReason}
                onChange={setCloseReason}
                placeholder="Paste Email response/thread here.. "
                modules={{
                  toolbar: false, // Disable the toolbar
                }}
              />
            </Box>

            <Box>
              <FormGroup sx={{ display: "flex", flexDirection: "column" }}>
                {checkboxes.map((checkbox) => (
                  <FormControlLabel
                    key={checkbox.id}
                    control={
                      <Checkbox
                        checked={checkbox.isChecked}
                        onChange={() => handleCheckboxChange(checkbox.id)}
                      />
                    }
                    label={checkbox.label}
                  />
                ))}
              </FormGroup>
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleModalClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button
                onClick={() => handleModalConfirm()}
                variant="contained"
                color="primary"
                disabled={!closeReason.trim()}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={transferModal}
          // onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reason for Transfer
            </Typography>
            
            <Box
              sx={{
                maxHeight: 200,
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px", // width of the scrollbar
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.primary[400],
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: 5,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={transformReason}
                onChange={setcurrentTransferId}
                placeholder="Text Here"
                modules={{
                  toolbar: false, // Disable the toolbar
                }}
              />
            </Box>
            <Box maxWidth={150} width={"100%"}>
              <CustomSelectThree
                title={"Select BDM Transform"}
                options={optionData}
                onChange={handleOptionChange}
                value={selectBdm}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleTransferModalClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button
                onClick={() => handleModalTransFerConfirm()}
                variant="contained"
                color="primary"
                disabled={!transformReason.trim() || !selectBdm.trim()}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={isJobPostingModalOpen}
          onClose={handleJobPostingCloseModal}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background:
                "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              height: "100vh",
            }}
          >
            <Box>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 4,
                }}
              >
                <Typography variant="h5" fontWeight={600}>
                  Job Posting
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button color="error" onClick={handleJobPostingCloseModal}>
                    Close
                  </Button>
                  <Button
                    color="inherit"
                    variant="outlined"
                    onClick={handleJobPostingSaveDraft}
                    disabled={jobPostStatus === "Submit"}
                    type="button"
                  >
                    Save as a Draft
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleJobPostingSaveSubmit}
                  >
                    Save and Submit
                  </Button>
                </Box>
              </Paper>
            </Box>

            <Box flex={1} overflow={"auto"} py={1}>
              <JobPostingForm
                selectDocument={selectDocument}
                jobDetails={jobDetails}
                setJobDetails={setJobDetails}
                skills={skills}
                setSkills={setSkills}
                organizationalInformation={organizationalInformation}
                setOrganizationalInformation={setOrganizationalInformation}
                errors={errors}
                allUsers={allUsers}
                setAssignedTo={setAssignedTo}
                assignedTo={assignedTo}
                industryNames={industryNames}
              />
            </Box>
          </Box>
        </Modal>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default Client;
