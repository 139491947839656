import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  NativeSelect,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { Link, useLocation } from "react-router-dom";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { styled } from "@mui/material/styles";
import theme from "../../Theme";
import { CustomSelect, PageHeader, SnackbarComponent } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { values } from "lodash";
import { getAllUsers, updateUserViaApi } from "../../store/auth/login/actions";
import { usersList } from "../../Data/Users";
const apiUrl = process.env.REACT_APP_API_URL;
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FormRow = ({ children }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "baseline",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};
const FormCell = ({ children, width = 300, flex = 1 }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: flex,
          flexBasis: width,
          display: "flex",
          gap: 1,
          alignItems: "flex-end",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

const UpdateUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const userDetail = location.state?.userDetail;

  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [showPassword, setShowPassword] = React.useState(true);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const optionUserGender = ["male", "female"];
  const optionsUserType = ["RATeam", "BDM", "RecruitmentTeam", "Admin"];
  const [optionsUserRole, setOptionsUserRole] = useState(
    userDetail && userDetail.userRole ? [userDetail.userRole] : []
  );

  // const optionsUserRole = ["Team Lead", "Supporting TL", "Team Member"];
  const [optionsUserLevel, setOptionsUserLevel] = useState([]);
  const [optionalReportTo, setOptionalReportTo] = useState(() => {
    if (userDetail && userDetail.userReportingTo) {
      const user = allUsers.find(
        (user) => user._id === userDetail.userReportingTo
      );
      return user ? [user.userFirstName] : [];
    } else {
      return [];
    }
  });

  const [userFirstName, setUserFirstName] = useState(
    "" || (userDetail && userDetail.userFirstName)
  );
  const [userLastName, setUserLastName] = useState(
    "" || (userDetail && userDetail.userLastName)
  );
  const [userSudoFirstName, setUserSudoFirstName] = useState(
    "" || (userDetail && userDetail.userSudoFirstName)
  );
  const [userSudoLastName, setUserSudoLastName] = useState(
    "" || (userDetail && userDetail.userSudoLastName)
  );
  const [userEmailId, setUserEmailId] = useState(
    "" || (userDetail && userDetail.userEmailId)
  );
  const [userSudoEmailId, setUserSudoEmailId] = useState(
    "" || (userDetail && userDetail.userSudoEmailId)
  );
  const [userPassword, setUserPassword] = useState(
    "" || (userDetail && userDetail.userPassword)
  );
  const [userGender, setUserGender] = useState(
    "" || (userDetail && userDetail.userGender)
  );
  const [userType, setUserType] = useState(
    "" || (userDetail && userDetail.userType)
  );
  const [userStatus, setUserStatus] = useState(
    "" || (userDetail && userDetail.userStatus)
  );
  const [userImage, setUserImage] = useState();
  const [userProfileImage, setUserProfileImage] = useState(
    userImage && userImage.image
      ? apiUrl + "/uploads/" + userImage.image
      : "" || (userDetail && userDetail.userProfileImage)
  );
  const [login, setLogin] = useState("" || (userDetail && userDetail.login));
  const [userLevel, setUserLevel] = useState(
    "" || (userDetail && userDetail.userLevel)
  );
  const [userRole, setUserRole] = useState(
    "" || (userDetail && userDetail.userRole)
  );

  const [userReportingTo, setUserReportingTo] = useState(() => {
    if (userDetail && userDetail.userReportingTo) {
      const user = allUsers.find(
        (user) => user._id === userDetail.userReportingTo
      );
      return user ? user.userFirstName : "";
    } else {
      return "";
    }
  });
  // const [userReportingTo, setUserReportingTo] = useState(() => {
  //   const user = allUsers.find(user => user._id === userDetail.userReportingTo);
  //   return user ? user.userFirstName : "";
  // });

  const handleChangeuserData = (type, value) => {
    switch (type) {
      case "userFirstName":
        setUserFirstName(value);
        break;
      case "userLastName":
        setUserLastName(value);
        break;
      case "userSudoFirstName":
        setUserSudoFirstName(value);
        break;
      case "userSudoLastName":
        setUserSudoLastName(value);
        break;
      case "userEmailId":
        setUserEmailId(value);
        break;
      case "userSudoEmailId":
        setUserSudoEmailId(value);
        break;
      case "userPassword":
        setUserPassword(value);
        break;
      case "userStatus":
        setUserStatus(value);
        break;

      default:
        break;
    }
  };

  const handleUserType = (title, value) => {
    setUserRole("");
    setUserLevel("");
    setUserReportingTo("");
    if (value === "RATeam") {
      setOptionsUserRole([
        "Team Lead",
        "Supporting TL",
        "Team Member",
        "Manager",
      ]);
      setOptionsUserLevel(["Senior", "Junior", "Fresher"]);
    } else if (value == "BDM") {
      setOptionsUserRole(["BDM", "DeliveryManager"]);
    } else if (value === "RecruitmentTeam") {
      setOptionsUserRole(["Recruiter", "DeliveryManager"]);
    } else if (value === "SuperAdmin") {
      setOptionsUserRole(["SuperAdmin"]);
    } else if (value === "Admin") {
      setOptionsUserRole(["Admin"]);
    }
    setUserType(value);
  };
  const handleUserRole = (title, value) => {
    setUserRole(value);
    let roleNames = [];
    if (value === "Team Member") {
      const DataRATeam = allUsers.filter(
        (user) =>
          user.userRole === "Team Lead" || user.userRole === "Supporting TL"
      );
      roleNames = DataRATeam.map((user) => user.userFirstName);
      setOptionalReportTo(roleNames);
    } else if (value === "Team Lead") {
      const DataTeamLead = allUsers.filter(
        (user) =>
          user.userRole === "Manager" && user.userType.includes("RATeam")
      );
      roleNames = DataTeamLead.map((user) => user.userFirstName);
      setOptionalReportTo(roleNames);
    } else if (value === "Supporting TL") {
      const Supporting = allUsers.filter(
        (user) => user.userRole === "Team Lead"
      );
      roleNames = Supporting.map((user) => user.userFirstName);
      setOptionalReportTo(roleNames);
    } else if (value === "Manager") {
      const DataTeamLead = allUsers.filter(
        (user) =>
          user.userRole === "SuperAdmin" || user.userType.includes("SuperAdmin")
      );
      roleNames = DataTeamLead.map((user) => user.userFirstName);
      setOptionalReportTo(roleNames);
      setOptionsUserLevel(["Manager"]);
    } else if (value === "BDM") {
      const DataTeamLead = allUsers.filter((user) => user.userRole === "Admin");
      roleNames = DataTeamLead.map((user) => user.userFirstName);
      setOptionalReportTo(roleNames);
      setOptionsUserLevel(["Senior BDM", "Junior BDM", "BDM"]);
    } else if (value === "DeliveryManager") {
      const DataTeamLead = allUsers.filter((user) => user.userRole === "Admin");
      roleNames = DataTeamLead.map((user) => user.userFirstName);
      setOptionalReportTo(roleNames);
      setOptionsUserLevel(["DeliveryManager"]);
    } else if (value === "Recruiter") {
      const DeliveryData = allUsers.filter(
        (user) => user.userRole === "DeliveryManager"
      );
      roleNames = DeliveryData.map((user) => user.userSudoFirstName);
      setOptionalReportTo(roleNames);
      setOptionsUserLevel(["Recruiter"]);
    } else if (value === "Admin") {
      const DataTeamLead = allUsers.filter(
        (user) =>
          user.userRole === "SuperAdmin" || user.userType.includes("SuperAdmin")
      );
      roleNames = DataTeamLead.map((user) => user.userFirstName);
      setOptionalReportTo(roleNames);
      setOptionsUserLevel(["Admin"]);
    }
  };

  const handleUserLevel = (title, value) => {
    setUserLevel(value);
  };
  const handleUserReportingTo = (title, value) => {
    setUserReportingTo(value);
  };
  const handleUerGender = (title, value) => {
    setUserGender(value);
  };

  const handleUserUpdate = () => {
    const userReportingToUserId = allUsers.find(
      (user) => user.userFirstName === userReportingTo
    );

    const action = {
      clientId: storeData.clientId,
      clientName: storeData.clientName,
      clientLogoUrl: storeData.clientLogoUrl,
      clientDb: storeData.clientDb,
      productExpiryDate: storeData.productExpiryDate,
      id: userDetail._id,
      userFirstName: userFirstName.trim(),
      userLastName: userLastName.trim(),
      userSudoFirstName:
        userSudoFirstName && userSudoFirstName.length > 0
          ? userSudoFirstName.trim()
          : "",
      userSudoLastName:
        userSudoLastName && userSudoLastName.length > 0
          ? userSudoLastName.trim()
          : "",
      userEmailId,
      userSudoEmailId:
        userSudoEmailId && userSudoEmailId.length > 0
          ? userSudoEmailId.trim()
          : "",
      userPassword,
      userGender: userGender && userGender.length > 0 ? userGender.trim() : "",
      userType: Array.isArray(userType) ? userType : [userType],
      userStatus: userStatus && userStatus.length > 0 ? userStatus.trim() : "",
      userProfileImage: userImage,
      userRegisterDate: new Date(),
      login: login && login.length > 0 ? login : "",
      userLevel: userLevel && userLevel.length > 0 ? userLevel.trim() : "",
      userRole: userRole && userRole.length > 0 ? userRole.trim() : "",
      userReportingTo: userReportingToUserId ? userReportingToUserId._id : "",
      client: client,
    };
    dispatch(updateUserViaApi(action));
    dispatch(getAllUsers(action));
  };
  const handleUserImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (allowedTypes.includes(file.type) && file.size <= maxSize) {
      setUserImage(file);
      setUserProfileImage(URL.createObjectURL(file));
    } else {
      enqueueSnackbar(
        "Invalid file. Please select a JPG or PNG file with a size less than 5MB.",
        { variant: "error" }
      );
    }
  };

  // Now you can access the hierarchy like this:

  // Separate users into different categories
  const managers = [];
  const teamLeads = [];
  const supportingTLs = [];
  const teamMembers = [];

  allUsers.forEach((user) => {
    switch (user.userRole) {
      case "Manager" || "Admin":
        managers.push(user);
        break;
      case "Team Lead":
        teamLeads.push(user);
        break;
      case "Supporting TL":
        supportingTLs.push(user);
        break;
      case "Team Member":
        teamMembers.push(user);
        break;
      default:
        // Handle any other roles if needed
        break;
    }
  });

  // Function to generate a random password with a length of 6 characters
  function generatePassword() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    let password = "";
    const length = 6; // Length of the password

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }

    return password;
  }

  // Function to generate username and password
  const handleGeneratePassword = () => {
    const pwd = generatePassword();
    setUserPassword(pwd);
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Update User"}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              justifyContent: "center",
              mb: 5,
            }}
          >
            <Box sx={{ flexBasis: 300 }}>
              <Card sx={{ width: "100%" }} elevation={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 5,
                    height: "100%",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <Avatar
                      alt="User Profile"
                      src={
                        userProfileImage && typeof userProfileImage === "string"
                          ? userProfileImage.startsWith("blob:")
                            ? userProfileImage
                            : `${apiUrl}/uploads/${userProfileImage}`
                          : ""
                      }
                      sx={{ width: 128, height: 128 }}
                    />
                    <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
                      <IconButton
                        size="small"
                        sx={{
                          bgcolor: "white",
                          border: "4px solid white",
                          "&:hover": {
                            bgcolor: theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText,
                          },
                        }}
                      >
                        <label htmlFor="upload-photo">
                          <VisuallyHiddenInput
                            id="upload-photo"
                            type="file"
                            onChange={handleUserImageUpload}
                          />
                          <CameraAltIcon fontSize="small" color="inherit" />
                        </label>
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                    <Chip label={"User Status"} size="small" />
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      label="Active"
                      value={
                        userStatus
                          ? userStatus === "Active"
                            ? true
                            : false
                          : false
                      }
                      onChange={(e) =>
                        handleChangeuserData(
                          "userStatus",
                          e.target.checked ? "Active" : "Inactive"
                        )
                      }
                    />
                  </Box>
                </Box>
              </Card>
            </Box>
            <Box sx={{ flex: 1, flexBasis: 300 }}>
              <Card sx={{ width: "100%", mb: 2 }} elevation={3}>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        variant="standard"
                        label="First Name"
                        placeholder="Hari"
                        size="small"
                        fullWidth
                        value={userFirstName}
                        onChange={(e) =>
                          handleChangeuserData("userFirstName", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        variant="standard"
                        label="Last Name"
                        placeholder="Reddy"
                        size="small"
                        fullWidth
                        value={userLastName}
                        onChange={(e) =>
                          handleChangeuserData("userLastName", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        variant="standard"
                        label="Email Id"
                        placeholder="josh@tektreeinc.com"
                        size="small"
                        fullWidth
                        value={userEmailId}
                        onChange={(e) =>
                          handleChangeuserData("userEmailId", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelect
                        title="Gender"
                        options={optionUserGender}
                        value={userGender}
                        onChange={handleUerGender}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        variant="standard"
                        label="Sudo First Name"
                        placeholder="Herry"
                        size="small"
                        fullWidth
                        value={userSudoFirstName}
                        onChange={(e) =>
                          handleChangeuserData(
                            "userSudoFirstName",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        variant="standard"
                        label="Sudo Last Name"
                        placeholder="Smith"
                        size="small"
                        fullWidth
                        value={userSudoLastName}
                        onChange={(e) =>
                          handleChangeuserData(
                            "userSudoLastName",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        variant="standard"
                        label="Sudo Email Id"
                        placeholder="herry@tektreellc.com"
                        size="small"
                        fullWidth
                        value={userSudoEmailId}
                        onChange={(e) =>
                          handleChangeuserData(
                            "userSudoEmailId",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}
                      >
                        <FormControl fullWidth variant="standard">
                          <InputLabel htmlFor="standard-adornment-password">
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            value={userPassword}
                            onChange={(e) =>
                              handleChangeuserData(
                                "userPassword",
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={handleGeneratePassword}
                        >
                          <PasswordOutlinedIcon fontSize="12" />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelect
                        title="User Type"
                        options={optionsUserType}
                        value={userType}
                        onChange={handleUserType}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelect
                        title="User Role"
                        options={optionsUserRole}
                        value={userRole}
                        onChange={handleUserRole}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelect
                        title="User Level"
                        options={optionsUserLevel}
                        value={userLevel}
                        onChange={handleUserLevel}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelect
                        title="Reporting to"
                        options={optionalReportTo}
                        value={userReportingTo}
                        onChange={handleUserReportingTo}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    p: 2,
                  }}
                >
                  <Button variant="outlined" onClick={handleUserUpdate}>
                    Update User
                  </Button>
                </Box>
              </Card>
              <Card sx={{ width: "100%" }} elevation={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    p: 2,
                    height: "100%",
                    gap: 2,
                  }}
                >
                  <Box>
                    {managers.map((manager) => {
                      const managerTeamLeads = teamLeads
                        .filter((lead) => lead.userReportingTo === manager._id)
                        .map((lead) => {
                          const leadSupportingTLs = supportingTLs
                            .filter(
                              (supportTL) =>
                                supportTL.userReportingTo === lead._id
                            )
                            .map((supportTL) => {
                              const supportTLMembers = teamMembers
                                .filter(
                                  (member) =>
                                    member.userReportingTo === supportTL._id
                                )
                                .map((member) => (
                                  <Box
                                    key={member._id}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 5,
                                      justifyContent: "space-between",
                                      bgcolor: theme.palette.grey[200],
                                      p: 1,
                                      mb: "1px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",

                                        gap: 1,
                                      }}
                                    >
                                      <Avatar sx={{ width: 30, height: 30 }}>
                                        {member.userFirstName.charAt(0)}
                                      </Avatar>
                                      <Typography>
                                        {member.userFirstName}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Chip
                                        label={member.userLevel}
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                      />
                                      <Chip
                                        label={member.userRole}
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                      />
                                    </Box>
                                  </Box>
                                ));

                              return (
                                <Box key={supportTL._id}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 5,
                                      justifyContent: "space-between",
                                      bgcolor: theme.palette.grey[200],
                                      p: 1,
                                      mb: "1px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <Avatar sx={{ width: 30, height: 30 }}>
                                        {supportTL.userFirstName.charAt(0)}
                                      </Avatar>
                                      <Typography>
                                        {supportTL.userFirstName}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Chip
                                        label={supportTL.userLevel}
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                      />
                                      <Chip
                                        label={supportTL.userRole}
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                      />
                                    </Box>
                                  </Box>
                                  {supportTLMembers}
                                  {}
                                </Box>
                              );
                            });

                          const leadAsignUsers = teamMembers
                            .filter(
                              (member) => member.userReportingTo === lead._id
                            )
                            .map((member) => (
                              <Box
                                key={member._id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 5,
                                  justifyContent: "space-between",
                                  bgcolor: theme.palette.grey[200],
                                  p: 1,
                                  mb: "1px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Avatar sx={{ width: 30, height: 30 }}>
                                    {member.userFirstName.charAt(0)}
                                  </Avatar>
                                  <Typography>
                                    {member.userFirstName}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Chip
                                    label={member.userLevel}
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                  />
                                  <Chip
                                    label={member.userRole}
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                  />
                                </Box>
                              </Box>
                            ));

                          return (
                            <Box key={lead._id}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                              >
                                {lead.userFirstName}'s{""} Team
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 5,
                                  justifyContent: "space-between",
                                  bgcolor: theme.palette.grey[200],
                                  p: 1,
                                  mb: "1px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Avatar sx={{ width: 30, height: 30 }}>
                                    {lead.userFirstName.charAt(0)}
                                  </Avatar>
                                  <Typography>{lead.userFirstName}</Typography>
                                  <Chip
                                    label={lead.userLevel}
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                  />
                                  <Chip
                                    label={lead.userRole}
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                  />
                                </Box>
                              </Box>
                              {leadAsignUsers}
                              {leadSupportingTLs}
                            </Box>
                          );
                        });
                      return (
                        <Box key={manager._id}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                              justifyContent: "space-between",
                              p: 1,
                              mb: "1px",
                            }}
                          >
                            {userType !== "RATeam" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Avatar sx={{ width: 30, height: 30 }}>
                                  {manager.userFirstName.charAt(0)}
                                </Avatar>
                                <Typography>{manager.userFirstName}</Typography>
                                <Chip
                                  label={manager.userRole}
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                />
                              </Box>
                            )}
                          </Box>
                          <Box
                            sx={{
                              ml: 4,
                              display: "flex",
                              gap: 2,
                              flexWrap: "wrap",
                            }}
                          >
                            {managerTeamLeads}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
          {/* <Box>{hierarchy}</Box> */}
        </LayoutSidebarMenu>
        <SnackbarComponent />
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default UpdateUser;
