
import {
    ADD_APPLICANT_NOTE_VIA_API,
    ADD_APPLICANT_VIA_API,
    ADD_DEGREE_VIA_API,
    ADD_DEGREES_VIA_API,
    ADD_RESUMESOURCES_VIA_API,
    AWS_RESUME_URL,
    AWS_RESUME_URL_VIA_API,
    DELETE_RECRUITER,
    GET_ALL_APPLICANTS_VIA_API,
    GET_ALL_DEGREES_VIA_API,
    GET_ALL_RESUMESOURCES_VIA_API,
    GET_PAGINATION_APPLICANT_VIA_API,
    GET_TOTAL_APPLICANTS_COUNT_VIA_API,
    JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API,
    JOB_POST_RECRUITER_VIA_API,
    SEARCH_DATE_JOB_POSTING_DATA_VIA_API,
    SEARCH_JOB_POST_RECRUITER_VIA_API,
    SUCCESS_ADD_APPLICANT,
    SUCCESS_ALL_RESUMESOURCES,
    SUCCESS_AWS_RESUME_URL,
    SUCCESS_DEGREE,
    SUCCESS_DEGREES,
    SUCCESS_JOB_POST_RECRUITER,
    SUCCESS_SEARCH_DATE_JOB_POSTING_DATA,
    SUCCESS_TOTAL_APPLICANTS_COUNT,
    TAG_AND_SUBMIT_APPLICANTS_API,
    TAG_APPLICANT_TO_JOB_VIA_API,
    TAGORSUBMIT_APPLICANTS_VIA_API,
} from "./actionType"


export const jobPostRecruiterViaApi=(action)=>{
    return{
        type:JOB_POST_RECRUITER_VIA_API,
        payload:action
    }
}
export const successjobPostRecruiter=(response)=>{
    return{
        type:SUCCESS_JOB_POST_RECRUITER,
        payload:response
    }
}
export const searchjobPostRecruiterViaApi=(actions)=>{
    return{
        type:SEARCH_JOB_POST_RECRUITER_VIA_API,
        payload:actions
    }
}
export const deleteRecruiter=()=>{
    return{
        type:DELETE_RECRUITER,
        payload:{}
    }
}
export const searchDateJobPostingDataViaApi=(action)=>{
    return{
        type:SEARCH_DATE_JOB_POSTING_DATA_VIA_API,
        payload:action
    }
}
export const successSearchDateJobPostingData=(reponse)=>{
    return{
        type:SUCCESS_SEARCH_DATE_JOB_POSTING_DATA,
        payload:reponse
    }
}
export const jobPostAssignedToRecruiterViaApi=(action)=>{
    return{
        type:JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API,
        payload:action
    }
}
export const addApplicantViaApi=(action)=>{
   
    return{
        type:ADD_APPLICANT_VIA_API,
        payload:action
    }
}

export const getAllApplicantsViaApi=(action)=>{
    return{
        type:GET_ALL_APPLICANTS_VIA_API,
        payload:action
    }
}
export const successAddApplicant=(reponse)=>{
    return{
        type:SUCCESS_ADD_APPLICANT,
        payload:reponse
    }
}

export const addResumeSourcesViaApi=(action)=>{
    return{
        type:ADD_RESUMESOURCES_VIA_API,
        payload:action
    }
}
export const getAllResumeSourceViaApi=(action)=>{
    return{
        type:GET_ALL_RESUMESOURCES_VIA_API,
        payload:action
    }
}
export const successAllResumeSources=(reponse)=>{
    return{
        type:SUCCESS_ALL_RESUMESOURCES,
        payload:reponse
    }
}
export const addApplicantNoteViaApi=(action)=>{
    return {
        type:ADD_APPLICANT_NOTE_VIA_API,
        payload:action
    }
}
export const tagApplicantToJobViaApi=(action)=>{
    return {
        type:TAG_APPLICANT_TO_JOB_VIA_API,
        payload:action
    }
}
export const getAllDegreesViaApi=(client)=>{
    
    return {
        type:GET_ALL_DEGREES_VIA_API,
        payload:client
    }
}
export const addDegreeViaApi=(action)=>{
    return {
        type:ADD_DEGREES_VIA_API,
        payload:action
    }
}
export const successDegree=(reponse)=>{
    return {
        type:SUCCESS_DEGREES,
        payload:reponse
    }
}
export const awsResumeUrl=(reponse)=>{
    return {
        type:AWS_RESUME_URL_VIA_API,
        payload:reponse
    }
}
export const successAwsResumeUrl=(reponse)=>{
    return {
        type:SUCCESS_AWS_RESUME_URL,
        payload:reponse
    }
}
export const getPaginationApplicantViaApi=(reponse)=>{
    return {
        type:GET_PAGINATION_APPLICANT_VIA_API,
        payload:reponse
    }
}
export const getTotalApplicantsCountViaSaga=(action)=>{
    return {
        type:GET_TOTAL_APPLICANTS_COUNT_VIA_API,
        payload:action
    }
}
export const successTotalApplicantsCount=(reponse)=>{
    return {
        type:SUCCESS_TOTAL_APPLICANTS_COUNT,
        payload:reponse
    }
}
export const tagAndSubmitApplicantViaApi =(action)=>{
    return {
        type:TAG_AND_SUBMIT_APPLICANTS_API,
        payload:action
    }
}
