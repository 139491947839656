import{
    DELETE_RECRUITER,
    SUCCESS_ADD_APPLICANT,
    SUCCESS_ALL_RESUMESOURCES,
    SUCCESS_AWS_RESUME_URL,
    SUCCESS_DEGREE,
    SUCCESS_DEGREES,
    SUCCESS_JOB_POST_RECRUITER,
    SUCCESS_SEARCH_DATE_JOB_POSTING_DATA,
    SUCCESS_TOTAL_APPLICANTS_COUNT
} from "./actionType"


const INIT_STATE = {
    jobPostings:[],
    applicants:[],
    resumeSources:[],
    degrees:[],
    awsResumeUrl:'',
    totalApplicants:0,
  };
  const recruiters = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SUCCESS_JOB_POST_RECRUITER: {
        const JobPostingsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          jobPostings: JobPostingsData,
        };
      }
      case SUCCESS_SEARCH_DATE_JOB_POSTING_DATA: {
        const JobPostingsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          jobPostings: JobPostingsData,
        };
      }
      case SUCCESS_ADD_APPLICANT: {
        const ApplicantsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          applicants: ApplicantsData,
        };
      }
      case SUCCESS_ALL_RESUMESOURCES: {
        const ResumeSourcesData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          resumeSources: ResumeSourcesData,
        };
      }
      case SUCCESS_DEGREES: {
        const degreesData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          degrees: degreesData,
        };
      }
      case SUCCESS_AWS_RESUME_URL: {
        const awsResumeUrlLink = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          awsResumeUrl: awsResumeUrlLink,
        };
      }
      case SUCCESS_TOTAL_APPLICANTS_COUNT: {
        const totalApplicantsCount = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          totalApplicants: totalApplicantsCount,
        };
      }
     
     
    
      case DELETE_RECRUITER: {
        
        return INIT_STATE;
      }
      default:
        return state;
    }
  };
  
  export default recruiters;
  