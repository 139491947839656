import React, { useState } from "react";
import {
  Box,
  Link,
  Paper,
  Step,
  StepLabel,
  Stepper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";

import TableFilterHeader from "../TableFilterHeader";
import theme from "../../Theme";
//////////////

import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjs from "dayjs";

///////////
///////////

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 6,
    width: "100%",
    left: "calc(-50% + 0px)",
    right: "calc(50% + 0px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: `rgba(${theme.palette.success.default},1)`,
      //backgroundImage: `linear-gradient( 95deg,rgba(${theme.palette.success.default},.5) 0%,rgba(${theme.palette.success.default},.5) 50%,rgba(${theme.palette.success.default},.5) 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: `rgba(${theme.palette.success.default},1)`,
      //backgroundImage: `linear-gradient( 95deg,rgba(${theme.palette.success.default},.5) 0%,rgba(${theme.palette.success.default},.5) 50%,rgba(${theme.palette.success.default},.5) 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 4,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 15,
  height: 15,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    //backgroundImage:"linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    //backgroundImage: `linear-gradient( 136deg, ${theme.palette.success.dark} 0%, ${theme.palette.success.dark} 50%, ${theme.palette.success.dark} 100%)`,
    background: `rgba(${theme.palette.success.default},1)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    //backgroundImage: `linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)`,
    //backgroundImage: `linear-gradient( 136deg, rgba(${theme.palette.success.default},.1) 0%, rgba(${theme.palette.success.default},1) 50%, rgba(${theme.palette.success.default},.1) 100%)`,
    background: `rgba(${theme.palette.success.default},1)`,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon sx={{ fontSize: 10 }} />,
    2: <GroupAddIcon sx={{ fontSize: 10 }} />,
    3: <VideoLabelIcon sx={{ fontSize: 10 }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {/* {icons[String(props.icon)]} */}
    </ColorlibStepIconRoot>
  );
}

// ColorlibStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
//   /**
//    * The label displayed in the step icon.
//    */
//   icon: PropTypes.node,
// };

///////////
///////////

const CustomSubmissionTilmeLine = ({submissions}) => {
  //////////////////////////////////////////////////////////
  const Applicants = useSelector((state) => state.Recruiters?.applicants);
  const ApplicantsData = Array.isArray(Applicants) ? Applicants : [];
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];

  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const submissionsArray = Array.isArray(submissions) ? submissions : [];
const filterDataPipeline = submissionsArray.map((sub, index) => {
  let matchedApplicant = ApplicantsData.find(applicant => applicant._id === sub.applicantId);

  if (matchedApplicant) {
    return {
      id: index,
      submitedBy:  `${allUsers.find((user)=>user._id===sub.addedBy).userSudoFirstName}${" "} ${allUsers.find((user)=>user._id===sub.addedBy).userSudoLastName}`  ,
      status: sub.applicantStatus,
      submitedOn:formatDateForDisplay(sub.addedOn) || "",
      candiateName:`${matchedApplicant.firstName||""}${" "}${matchedApplicant.middleName||""}${" "}${matchedApplicant.lastName||""}`,
      steps: "Submission",
      contact:`${ matchedApplicant.mobileNumbers
        ? matchedApplicant.mobileNumbers
            .filter(item => item.number) // Filter out objects without an email
            .map(item => item.number)
            .join(", ")
        : ""}/${matchedApplicant.state},${matchedApplicant.city}`,
      payRate: `${matchedApplicant.payRateSalary.currency}/${matchedApplicant.payRateSalary.minAmount}/${matchedApplicant.payRateSalary.maxAmount}/${matchedApplicant.payRateSalary.paySchedule}/${matchedApplicant.payRateSalary.taxTerm}`,
      ...matchedApplicant // Merging applicant data
    };
  }

  return null; // In case no matching applicant is found, return null
}).filter(data => data !== null); // Remove null entries
  const tableColumns = [
    {
      field: "candiateName",
      headerName: "Name",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => {
        return (
          <>
            <Link sx={{ textDecoration: "none" }}>
              <Typography noWrap sx={{ fontSize: 12 }}>
                {params.value}
              </Typography>
            </Link>
          </>
        );
      },
    },
    {
      field: "SubmittedbyON",
      headerName: "Submitted by/ON",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => {
        const { submitedOn, submitedBy } = params.row;
        return (
          <>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {submitedOn}
            </Typography>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {submitedBy}
            </Typography>
          </>
        );
      },
    },
    {
      field: "contact",
      headerName: "Contact/Location",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => {
        const { contact, location } = params.row;
        return (
          <>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {contact}
            </Typography>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {location}
            </Typography>
          </>
        );
      },
    },
    {
      field: "Pay Rate/Work Auth",
      headerName: "Pay Rate/Work Auth",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => {
        const { payRate, workAuth } = params.row;
        return (
          <>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {payRate}
            </Typography>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {workAuth}
            </Typography>
          </>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
  ];

  const submitedRows = [
    {
      id: 1,
      name: "Seth Tuato",
      submitedOn: "08/09/24 10:54:24",
      submitedBy: "Sen Reddingt",
      contact: "+13615222293",
      location: "Katy",
      payRate: "$200,000.00/Yearly/Full Time",
      workAuth: "US Citizen",
      status: "Submitted To Client",
      steps: "Confirmation",
    },
    {
      id: 2,
      name: "Julio Rondon",
      submitedOn: "08/09/24 10:54:24",
      submitedBy: "Sen Reddingt",
      contact: "(832) 836-0273",
      location: "Houston",
      payRate: "$150,000.00/Yearly/Full Time",
      workAuth: "US Citizen",
      status: "Submitted To Client",
      steps: "Placement",
    },
    {
      id: 3,
      name: "Julio Rondon",
      submitedOn: "09/09/24 10:54:24",
      submitedBy: "Sen Reddingt",
      contact: "(832) 836-0273",
      location: "Houston",
      payRate: "$150,000.00/Yearly/Full Time",
      workAuth: "US Citizen",
      status: "Submitted To Client",
      steps: "Submission",
    },
  ];

  const steps = [
    "Pipeline",
    "Submission",
    "Client Submisson",
    "Interview",
    "Confirmation",
    "Placement",
    "Not Joined",
  ];

  return (
    <React.Fragment>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            mt: 3,

            overflowX: "auto",
            "&::-webkit-scrollbar": {
              //width: "8px", // width of the scrollbar
              height: 8,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.grey[300],
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.primary.light, // color of the thumb
              borderRadius: "10px", // roundness of the thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: theme.palette.primary.dark, // color of the thumb on hover
            },
          }}
        >
          <Table aria-label="spanning table" size="small">
            <TableHead>
              <TableRow sx={{ height: 60 }}>
                {tableColumns.map((column) => (
                  <TableCell
                    key={column.field}
                    sx={{
                      maxWidth: column.minWidth,
                      minWidth: column.minWidth,
                      width: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {column.renderHeader ? (
                        column.renderHeader({
                          headerName: column.headerName,
                        }) // Pass the header name as params
                      ) : (
                        <Typography
                          noWrap
                          sx={{
                            backgroundColor: "#FFFFFF",
                            fontSize: "15px",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {column.headerName}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filterDataPipeline.map((row) => (
                <React.Fragment key={row.id}>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.field}
                        sx={{
                          maxWidth: column.minWidth,
                          minWidth: column.minWidth,
                          width: "auto",
                          border: 0,
                          py: 2,
                        }}
                      >
                        {column.renderCell ? (
                          column.renderCell({
                            value: row[column.field],
                            row,
                          })
                        ) : (
                          <Typography noWrap sx={{ fontSize: 12 }}>
                            {row[column.field]}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={tableColumns.length}
                      sx={{ padding: 0 }}
                    >
                      <Stepper
                        alternativeLabel
                        activeStep={steps.indexOf(row.steps)}
                        connector={<ColorlibConnector />}
                        sx={{ mb: 2 }}
                      >
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <StepLabel
                              StepIconComponent={ColorlibStepIcon}
                              sx={{
                                fontSize: "10px",

                                "& .MuiStepLabel-label": {
                                  fontSize: 12,
                                  marginTop: 1,
                                },
                              }}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default CustomSubmissionTilmeLine;