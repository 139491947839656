import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddApplicant,
  AddApplicantForm,
  CustomButton,
  CustomDetailViewSection,
  CustomSubmissionTilmeLine,
  JobPostingForm,
  KeyData,
  MainBackground,
  SectionTitle,
} from "../../Components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import {
  addApplicantViaApi,
  addDegreeViaApi,
  addResumeSourcesViaApi,
  getAllDegreesViaApi,
  getAllResumeSourceViaApi,
} from "../../store/recruiter/action";
import { debounce } from "lodash";

const TooltipIconButton = ({
  title,
  arrow = false,
  pointer = "right",
  icon = <ArrowBackIcon />,
}) => {
  const styledIcon = React.cloneElement(icon, {
    sx: { fontSize: 14 },
  });
  return (
    <Box>
      <Tooltip title={title} arrow={arrow} placement={pointer}>
        <IconButton>{styledIcon}</IconButton>
      </Tooltip>
    </Box>
  );
};

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ApplicantDetails = () => {
  const [tabShow, setTabShow] = React.useState(0);
  const location = useLocation();

  const applicant = location.state?.applicant || {};
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [firstName, setFirstName] = useState(applicant.firstName);
  const [middleName, setMiddleName] = useState(applicant.middleName);
  const [lastName, setLastName] = useState(applicant.lastName);
  const [gender, setGender] = useState(applicant.gender);
  const [emailAddress, setEmailAddress] = useState(
    applicant ? applicant?.emailAddress : []
  );
  const [mobileNumbers, setMobileNumbers] = useState(
    applicant ? applicant?.mobileNumbers : []
  );
  const [state, setState] = useState(applicant.state);
  const [city, setCity] = useState(applicant.city);
  const [zipCode, setZipCode] = useState(applicant.zipCode);
  const [selectApplicatResumes, setSelectApplicantResumes] = useState(applicant.resumes||[]);
  const lastResume=applicant.resumes?applicant.resumes[applicant.resumes?.length - 1]:[];
  const { originalFileName } = lastResume;
  const [resume, setResume] = useState(originalFileName||'');
  
  const [experienceYears, setExperienceYears] = useState(
    applicant.experienceYears
  );
  const [experienceMonths, setExperienceMonths] = useState(
    applicant.experienceMonths
  );
  const [jobTitle, setJobTitle] = useState(applicant.jobTitle);
  const [source, setSource] = useState(applicant.source);
  const [availability, setAvailability] = useState(applicant.availability);
  const [payRateSalary, setPayRateSalary] = useState(applicant.payRateSalary);
  const [skills, setSkills] = useState(applicant.skills);
  const [workAuthorization, setWorkAuthorization] = useState(
    applicant.workAuthorization
  );
  const [relocate, setRelocate] = useState(applicant.relocate);
  const [editApplicantModalOpen, setEditApplicantModalOpen] = useState(false);
  const [educationDetails, setEducationDetails] = useState(
    applicant ? applicant.educationDetails : {}
  );

  const [personalDetails, setPersonalDetails] = useState(
    applicant ? applicant.personalDetails : {}
  );
  const [professionalDetails, setProfessionalDetails] = useState(
    applicant ? applicant.professionalDetails : {}
  );

  const resumeSources = useSelector((state) => state.Recruiters?.resumeSources);

  const resumeSourcesNames = Array.isArray(resumeSources)
    ? resumeSources.map((item) => item.resumeSource)
    : [];
  const degrees = useSelector((state) => state.Recruiters?.degrees);
  const degreesNames = Array.isArray(degrees)
    ? degrees.map((item) => item.degreeName)
    : [];
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const [applicantDocId, setApplicantDocId] = useState("");
  const [errors, setErrors] = useState({});
  const handleTabChange = (event, newValue) => {
    setTabShow(newValue);
  };

  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);

  const handleAddApplicantCloseModal = () => {
    setIsAddApplicantModalOpen(!isAddApplicantModalOpen);
    setEditApplicantModalOpen(!editApplicantModalOpen)
  };
  const validateForm = () => {
    const newErrors = {};

    // Check if firstName is present and not empty inside personalDetails
    if (!firstName) {
      newErrors.firstName = "First Name is required in Personal Details";
    }

    // Check if lastName is empty
    if (!lastName) {
      newErrors.lastName = "Last Name is required in Personal Details";
    }

    // Validate personalDetails fields
    if (!gender) {
      newErrors.gender = "Gender is required in Personal Details";
    }
    const primaryEmail = emailAddress.find((email) => email.type === "primary");
    // Check if the primary email is empty
    if (!primaryEmail || !primaryEmail.email || !primaryEmail.email.trim()) {
      newErrors.primaryEmail = "Primary Email Address is required in Personal Details";
    }

    // Validate primary mobile number"primary"
    const primaryMobile = mobileNumbers.find((mobile) => mobile.type === "primary");
    if (!primaryMobile || !primaryMobile.number || !primaryMobile.number.trim()) {
      newErrors.mobileNumber = "Primary Mobile Number is required in Personal Details";
    }
    if (!state) {
      newErrors.state = "state is required in Personal Details";
    }
    if (!city) {
      newErrors.city = "city is required in Personal Details";
    }

    // Validate professionalDetails fields
    if (!resume) {
      newErrors.resumes = "resumes is required";
    }
    if (!payRateSalary?.minAmount) {
      newErrors.payRateMinAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!payRateSalary?.maxAmount) {
      newErrors.payRateMaxAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!payRateSalary?.paySchedule) {
      newErrors.payRatePaySchedule =
        "paySchedule is required in Professional Details";
    }
    if (!payRateSalary?.taxTerm) {
      newErrors.payRateTaxTerm = "jobType is required in Professional Details";
    }
    if (!experienceYears) {
      newErrors.years = "years is required in Professional Details";
    }
    if (!experienceMonths) {
      newErrors.months = "months is required in Professional Details";
    }
    if (!jobTitle) {
      newErrors.jobTitle = "title is required in Professional Details";
    }
    if (!source) {
      newErrors.source =
        "source is required in Professional Details";
    }
    if (!availability) {
      newErrors.availability =
        "availability is required in Professional Details";
    }
    if (
      !skills || skills.length === 0
    ) {
      newErrors.skills = "At least one  skill is required";
    }
    if (!workAuthorization) {
      newErrors.workAuthorization =
        "At least one  workAuthorization is required";
    }
    educationDetails.forEach((detail, index) => {
      if (!detail.schoolName) {
        newErrors[`educationDetails[${index}].schoolName`] = "School Name is required";
      }
      if (!detail.degree) {
        newErrors[`educationDetails[${index}].degree`] = "Degree is required";
      }
      if (!detail.country) {
        newErrors[`educationDetails[${index}].country`] = "Country is required";
      }
      if (!detail.state) {
        newErrors[`educationDetails[${index}].state`] = "State is required";
      }
      if (!detail.city) {
        newErrors[`educationDetails[${index}].city`] = "City is required";
      }
    });

    setErrors(newErrors);
    // Returns true if no errors exist
    return Object.keys(newErrors).length === 0;
  };

  const trimObjectValuesExceptResume = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (key === "resume") {
        acc[key] = value; // Skip trimming for the 'resume' field
      } else if (Array.isArray(value)) {
        acc[key] = value;
      } else if (typeof value === "string") {
        acc[key] = value.trim(); // Trim strings
      } else if (typeof value === "object" && value !== null) {
        acc[key] = trimObjectValuesExceptResume(value); // Recursively trim nested objects
      } else {
        acc[key] = value; // Handle other types as-is
      }
      return acc;
    }, {});
  };
  const handleSaveSubmitApplicant = debounce(async () => {
    if (validateForm()) {
      
      
         // Define action object for the second API call
      const trimEducationDeatils = trimObjectValuesExceptResume(educationDetails);
      const educationDetailsLast =educationDetails[educationDetails.length - 1];
      const { degree } = educationDetailsLast;
      const payRateSalaryDaetils =
        trimObjectValuesExceptResume(payRateSalary);
      // Define action object for the second API call
      const action = {
        addedBy: storeData._id,
        addedOn: new Date().toISOString(),
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        gender: gender,
        emailAddress: emailAddress,
        mobileNumbers: mobileNumbers,
        state: state,
        city: city,
        zipCode: zipCode,
        resume: resume,
        experienceYears: experienceYears,
        experienceMonths: experienceMonths,
        payRateSalary: payRateSalaryDaetils,
        skills: skills,
        jobTitle: jobTitle,
        source: source,
        availability: availability,
        workAuthorization: workAuthorization,
        educationDetails: educationDetails,
        id: applicant?._id || "",
        resumeSource: source?.trim() || "",
        degree: degree?.trim() || "",
        client: client,
        resumes:selectApplicatResumes
      };
      const formData = new FormData();

      // Basic fields (strings, numbers)
      formData.append('addedBy', storeData._id);
      formData.append('addedOn', new Date().toISOString());
      formData.append('firstName', firstName);
      formData.append('middleName', middleName);
      formData.append('lastName', lastName);
      formData.append('gender', gender);
      formData.append('state', state);
      formData.append('city', city);
      formData.append('zipCode', zipCode);
      formData.append('resume', resume);  // Assuming resume is a file, otherwise stringify if it's data.
      formData.append('experienceYears', experienceYears);
      formData.append('experienceMonths', experienceMonths);
      formData.append('jobTitle', jobTitle);
      formData.append('source', source);
      formData.append('availability', availability);
      formData.append('workAuthorization', workAuthorization);
      formData.append('id', applicant?._id || "");
      formData.append('resumeSource', source?.trim() || "");
      formData.append('degree', degree);
      formData.append('client', client);

      // Handling arrays (emailAddress, mobileNumbers, skills, etc.)
      formData.append('emailAddress', JSON.stringify(emailAddress));
      formData.append('mobileNumbers', JSON.stringify(mobileNumbers));
      formData.append('skills', JSON.stringify(skills));
      formData.append('resumes', JSON.stringify(selectApplicatResumes));
      // Handling nested objects (payRateSalary, educationDetails)
      formData.append('payRateSalary', JSON.stringify(payRateSalaryDaetils));
      formData.append('educationDetails', JSON.stringify(educationDetails));
      // // Dispatch the actions
      await dispatch(addApplicantViaApi(formData));
      await dispatch(addResumeSourcesViaApi(action));
      await dispatch(addDegreeViaApi(action));
      setTimeout(() => {
        const action = { client: client };
        dispatch(getAllResumeSourceViaApi(action));
        dispatch(getAllDegreesViaApi(action));
        handleAddApplicantCloseModal();
      }, 2000);
    }
  }, 2000);

  const ApplicantsList = [
    {
      id: 1,
      applicantID: "458912",
      applicantName: {
        firstName: "John",
        middleName: "A.",
        lastName: "Doe",
      },
      gender: "Male",
      emailAddress: "john.doe@example.com",
      mobileNumber: "(555) 123-4567",
      source: "Career Builder",
      location: {
        city: "Vallejo",
        state: "California",
        zipCode: "94590",
      },
      applicantStatus: "New lead",
      jobTitle: "Project Manager",
      ownership: "Patrick Hawkins",
      workAuthorization: "H1-B",
      createdBy: "Admin",
      createdOn: "08/26/24 21:19:34",
      availability: "Availability",
      payRate: "USD/200,000.00/Yearly/Full Time",
      resume: "https://msnlabs.com/img/resume-sample.pdf",
      resumeTitle: "Use Document Title",
      resumeInfo:
        "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
    },
  ];

  function flattenObject(obj, parentKey = "", result = {}) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}_${key}` : key;

        if (
          typeof obj[key] === "object" &&
          obj[key] !== null &&
          !Array.isArray(obj[key])
        ) {
          flattenObject(obj[key], newKey, result);
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  }

  // const flattenedApplicantsList = ApplicantsList.map((applicant) =>
  //   flattenObject(applicant)
  // );
  const flattenedPersonalDetails = [personalDetails].map((applicant) =>
    flattenObject(applicant)
  );
  const flattenedProfessionalDetails = [professionalDetails].map((applicant) =>
    flattenObject(applicant)
  );

  const flattenedmobileNumber = [payRateSalary].map((applicant) => {
    flattenObject(applicant);
  });
  // const flattenedApplicantsList = ApplicantsList.map((applicant) =>
  //   flattenObject(applicant)
  // );
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const noteFilter = applicant?.note?.map((note) => ({
    addedBy: allUsers.find((user) => user._id === note.addedBy)
      ?.userSudoFirstName,
    addedOn: formatDateForDisplay(note.addedOn),
    note: note.note,
  }));
  const mobileresult = {};
  const emailResult = {};
  // Dynamically map types to keys without conditions
  mobileNumbers.forEach(m => {
    const key = `mobile${m.type.charAt(0).toUpperCase() + m.type.slice(1)}`;
    mobileresult[key] = m.number;
  });
  emailAddress.forEach(m => {
    const key = `email${m.type.charAt(0).toUpperCase() + m.type.slice(1)}`;
    emailResult[key] = m.email;
  });

  const takePersonalDetails = [{
    firstName,
    middleName,
    lastName,
    gender,
    ...mobileresult,
    ...emailResult,
    state,
    city,
    zipCode,
  }]
  const skillsString = skills.join(", ");
  const takeProfessionalDetails = [
    {
      experienceYears,
      experienceMonths,
      jobTitle,
      source,
      availability,
      skills: skillsString,
      workAuthorization,
      relocate,
      ...payRateSalary
    }
  ]
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Applicant Details"}>
          <Grid container spacing={2}>
            <Grid item xs={8.5}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <MainBackground>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Link
                          component="button" // Use `button` for a clickable element
                          to={`/applicants`}
                        >
                          <TooltipIconButton title="back" />
                        </Link>
                        <Tooltip title={""}>
                          <CircleIcon sx={{ fontSize: 12 }} />
                        </Tooltip>
                        <Chip
                          label="Status"
                          color="error"
                          size="small"
                          variant="outlined"
                          sx={{ px: 0.5, borderRadius: 1 }}
                        />
                      </Box>
                      <Box flex={1} bgcolor={"red"}></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <TooltipIconButton
                          title="left"
                          icon={<ChevronLeftIcon />}
                        />
                        <TooltipIconButton
                          title="right"
                          icon={<ChevronRightIcon />}
                        />
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          {applicant?.applicantId}-{firstName} {middleName}{" "}
                          {lastName}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <ButtonGroup
                            variant="outlined"
                            size="small"
                            color="error"
                          >
                            <Button
                              startIcon={<EditIcon fontSize="inherit" />}
                              onClick={handleAddApplicantCloseModal}
                            >
                              Edit
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <KeyData title={"job Title"} value={jobTitle} />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Location"}
                            value={`${city},
                            ${state},
                            ${zipCode}`}
                          />
                          {mobileNumbers.map((number, index) => (
                            <Box
                              key={index}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Divider orientation="vertical" flexItem />
                              <KeyData
                                title={`${number.type} MobileNumber`}
                                value={number?.number}
                              />
                            </Box>
                          ))}
                          {emailAddress.map((email, index) => (
                            <Box
                              key={index}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Divider orientation="vertical" flexItem />
                              <KeyData
                                title={`${email.type} EmailAddress`}
                                value={email?.email}
                              />
                            </Box>
                          ))}

                          {/* <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Email Address"}
                            value={personalDetails?.emailAddress}
                          /> */}
                          <Divider orientation="vertical" flexItem />
                          <KeyData title={"Source"} value={source} />

                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Work Authorization"}
                            value={workAuthorization}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </MainBackground>

                <Tabs
                  value={tabShow}
                  onChange={handleTabChange}
                  sx={{ mt: 4, mb: 2 }}
                >
                  <Tab label="Snapshot" />
                  <Tab label="Personal Details" />
                  <Tab label="Profession Details" />
                  <Tab label="Employer Details" />
                  <Tab label="Employment Test Results" />
                  <Tab label=" Activities" disabled />
                  {/* 
                  
                  
                  <Tab label=" Email Merges" disabled /> */}
                </Tabs>
                <TabPanel value={tabShow} index={0}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 3,
                          }}
                        >
                          <SectionTitle title="Notes" />
                          <ButtonGroup>
                            <CustomButton title="Applicants" value={2} />
                            <CustomButton title="Submissions" value={0} />
                            <CustomButton title="Pipeline" value={2} />
                          </ButtonGroup>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <CustomButton title="Add" />
                        </Box>
                      </Box>
                    )}
                    data={noteFilter}
                  />
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Documents" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <CustomButton title="Add" active />
                        </Box>
                      </Box>
                    )}
                    bodyData={() => (
                      <Box>
                        <Typography>
                          {jobTitle}{" "}
                        </Typography>
                        <Typography variant="caption">
                          {ApplicantsList[0].resumeInfo}
                        </Typography>
                      </Box>
                    )}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={1}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Personal Details" />
                      </Box>
                    )}
                    title={"Job Details"}
                    data={takePersonalDetails}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={2}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Personal Details" />
                      </Box>
                    )}
                    title={"Job Details"}
                    data={takeProfessionalDetails}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={3}>
                  <MainBackground>No data available</MainBackground>
                </TabPanel>
                <TabPanel value={tabShow} index={4}>
                  <MainBackground>
                    No data available v Employment Test Results
                  </MainBackground>
                </TabPanel>
                <TabPanel value={tabShow} index={5}>
                  <MainBackground>
                    No data available v Employment Test Results
                  </MainBackground>
                </TabPanel>
                {/*
                 */}
              </Box>
            </Grid>
            <Grid item xs={3.5}>
              <MainBackground>adf</MainBackground>
            </Grid>
          </Grid>
        </LayoutSidebarMenu>

        <Modal
          open={isAddApplicantModalOpen}
          onClose={handleAddApplicantCloseModal}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background:
                "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              height: "100vh",
            }}
          >
            <Box>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 4,
                }}
              >
                <Typography variant="h5" fontWeight={600}>
                  Add Applicant
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button color="error" onClick={handleAddApplicantCloseModal}>
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveSubmitApplicant}
                  >
                    Submit Applicant
                  </Button>
                </Box>
              </Paper>
            </Box>

            <Box flex={1} overflow={"auto"} p={1}>
              <AddApplicantForm
                firstName={firstName}
                setFirstName={setFirstName}
                middleName={middleName}
                setMiddleName={setMiddleName}
                lastName={lastName}
                setLastName={setLastName}
                gender={gender}
                setGender={setGender}
                emailAddress={emailAddress}
                mobileNumbers={mobileNumbers}
                setMobileNumbers={setMobileNumbers}
                setEmailAddress={setEmailAddress}
                state={state}
                setState={setState}
                city={city}
                setCity={setCity}
                zipCode={zipCode}
                setZipCode={setZipCode}
                resume={resume}
                setResume={setResume}
                experienceYears={experienceYears}
                setExperienceYears={setExperienceYears}
                experienceMonths={experienceMonths}
                setExperienceMonths={setExperienceMonths}
                jobTitle={jobTitle}
                setJobTitle={setJobTitle}
                source={source}
                setSource={setSource}
                availability={availability}
                setAvailability={setAvailability}
                payRateSalary={payRateSalary}
                setPayRateSalary={setPayRateSalary}
                skills={skills}
                setSkills={setSkills}
                workAuthorization={workAuthorization}
                setWorkAuthorization={setWorkAuthorization}
                relocate={relocate}
                setRelocate={setRelocate}
                editApplicantModalOpen={editApplicantModalOpen}
                errors={errors}
                resumeSourcesNames={resumeSourcesNames}
                educationDetails={educationDetails}
                setEducationDetails={setEducationDetails}
                degreesNames={degreesNames}
                setSelectApplicantResumes={setSelectApplicantResumes}
                selectApplicatResumes={selectApplicatResumes}
              />
            </Box>
          </Box>
        </Modal>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default ApplicantDetails;
