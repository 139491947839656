import React, { useEffect, useRef, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Radio,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  Backgrounds,
  DataWidget,
  FilterHeader,
  MainBackground,
  SectionTitle,
} from "../../Components";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import theme from "../../Theme";

import {
  adminApprovedCompaniesViaApi,
  searchNotActiveCompanies,
  transferBdmViaApi,
} from "../../store/companiesList/action";
import { SnackbarComponent } from "../../Components";
import { getAllUsers } from "../../store/auth/login/actions";
import { ShimmerTable } from "react-shimmer-effects";
import { stopLoadingRedux } from "../../store/reports/action";
import ReactQuill from "react-quill";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment-timezone";
import dayjs from 'dayjs';
//mport dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import companyStatus from "../../Components/companyStatusElements/companyStatus";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York'); // Set default timezone
const ApprovalStatus = () => {
  const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked,companyOnProcess } = companyStatus;
  const dispatch = useDispatch();
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedBDMName, setSelectedBDMName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedRowDetails, setSelectedRowDetails] = useState(null); // State to hold selected row details
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const storeData = useSelector((state) => state.Login.storeData);
  const client=storeData.clientDb
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
const [searchApprovalBDMName,setSearchApprovalBDMName]=useState([]);
const [searchApprovalCompanyName,setSearchApprovalCompanyName]=useState('');
const [searchApprovalJobTitle,setSearchApprovalJobTitle]=useState('');
const [searchApprovalJobLocation,setSearchApprovalJobLocation]=useState('');
const [searchApprovalFromDate,setSearchApprovalFromDate]=useState('');
const [searchApprovalToDate,setSearchApprovalToDate]=useState('');
const [searchApprovalStatus,setApprovalStatus]=useState([]);
// const [searchApprova]

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  let ApprovableCompanies =
    useSelector((state) => state.CompaniesList?.approvableCompanies) || [];

  // Filter and map approvable companies based on specified conditions
  ApprovableCompanies = ApprovableCompanies.filter(
    (company) =>
      company.status === companyOnProcess &&
      company.jobDetails &&
      company.jobDetails.some(
        (jobDetails) =>
          jobDetails &&
          (jobDetails.status === "Close" ||
            jobDetails.status === "Hold" ||
            jobDetails.status === "Backout" ||
            company.transFerBdm) &&
          jobDetails.approvalStatus === false
      )
  );


  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz('America/New_York');
    const isDST = moment.tz(date, 'America/New_York').isDST();
    const timeZoneAbbr = isDST ? 'EDT' : 'EST';
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
 
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(searchNotActiveCompanies(action));
  }, []);
  
  const ShowData = ({ title = "", data = "", color = "inherit" }) => {
    return (
      <Box>
        <Box>
          <Typography variant="caption" color={color}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography color={color}>{data}</Typography>
        </Box>
        <Divider aria-hidden="true" sx={{ mt: 1 }} />
      </Box>
    );
  };
  const [ApprovableData, setApprovableData] = useState([]);
  const filterSearchData = Array.isArray(ApprovableData) 
  ? ApprovableData.filter((data) => {
      const lowerCaseCompanyName =data.companyName?.toLowerCase() || "";
      const lowerCaseJobPosition = data.jobTitle?.toLowerCase() || "";
      const lowerCaseJobLocation = data.jobLocation?.toLowerCase() || "";
     const lowerCaseBDMName=data.bdmName||""
      const statusType = data.statusAdvice  || 'Pending';
      const  bdmRequestDate=new Date(data.bdmRequestDate); 
      
    
      const lowerCaseSearchJobPosition = searchApprovalJobTitle?.toLowerCase() || "";
      const lowerCaseSearchCompany = searchApprovalCompanyName?.toLowerCase() || "";
      const lowerCaseSearchJobLocation = searchApprovalJobLocation?.toLowerCase() || "";
      const SearchStatus=searchApprovalStatus||[];
      const SearchBDMName=searchApprovalBDMName||[];
      const SearchApprovalFromDate=searchApprovalFromDate;
      const SearchApprovalToDate=searchApprovalToDate;
      const isDateInRange =
      (!SearchApprovalFromDate || bdmRequestDate >= new Date(SearchApprovalFromDate)) &&
      (!SearchApprovalToDate || bdmRequestDate <= new Date(SearchApprovalToDate));
      return (lowerCaseJobPosition.includes(lowerCaseSearchJobPosition)&&
      isDateInRange 
      &&lowerCaseCompanyName.includes(lowerCaseSearchCompany)
      &&lowerCaseJobLocation.includes(lowerCaseSearchJobLocation)&&
      (SearchStatus.length === 0 || SearchStatus.includes(statusType))&&
      (SearchBDMName.length === 0 || SearchBDMName.includes(lowerCaseBDMName))
     
    )
    })
  : [];
  useEffect(() => {
    if (ApprovableCompanies) {
      const transformedData = ApprovableCompanies.map((company, index) => {
        const jobDetails =
          company.jobDetails && company.jobDetails.length > 0
            ? company.jobDetails[0]
            : {};
        const userData =
          allUsers && allUsers.find((user) => user._id === company.addedBy);
        return {
          id: index + 1,
          ID: company._id,
          bdmName: userData.userSudoFirstName || "",
          companyName: company.companyName,
          status: company.status,
          jobTitle: jobDetails?.jobTitle,
          jobLocation: jobDetails?.jobLocation,
          closeReason: jobDetails?.closeReason || "",
          statusAdvice: jobDetails?.statusAdvice || "",
          bdmClickStatus:jobDetails?.status||"",
          approvalStatus: jobDetails?.approvalStatus || false,
          transFerBdm: company.transFerBdm || [],
          bdmRequestDate:company.bdmRequestDate||company.addedOn
        };
      });
      let sortedFilteredResults = transformedData.sort((a, b) => {
        const dateA = new Date(a.bdmRequestDate);
        const dateB = new Date(b.bdmRequestDate);
        return dateA - dateB; // Sort in ascending order
      });
  
      sortedFilteredResults = sortedFilteredResults.map((company, index) => ({
        ...company,
        id: index + 1
      }));
      setApprovableData(sortedFilteredResults);
    }
  }, [ApprovableCompanies]);

  const [closeReasonContent, setCloseReasonContent] = useState("");
  const [closeReasonModal, setCloseReasonModal] = useState(false);
  const quillRef = useRef(null);
  const handleCloseReasonModal = () => {
    setCloseReasonModal(false);
    setCloseReasonContent("");
  };
  const containsHTML = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    return Array.from(div.childNodes).some((node) => node.nodeType === 1); // Check if any child node is an element
  };
  const convertToHTML = (text) => {
    return `<p>${text}</p>`;
  };
  const handleClick = (reason) => {
    if (containsHTML(reason)) {
      setCloseReasonContent(reason);
      setCloseReasonModal(true);
    } else {
      const htmlReason = convertToHTML(reason);
      setCloseReasonContent(htmlReason);
      setCloseReasonModal(true);
    }
  };
  const companyColumn = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "bdmRequestDate",
      headerName: "BDM Request Date",
      width: 180,
      flex:1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const {row}=params
        const date=formatDateForDisplay(row.bdmRequestDate)
        return date;
      },
      renderHeader: (params) => <FilterHeader params={params} type={"date"} setSearchApprovalFromDate={setSearchApprovalFromDate} setSearchApprovalToDate={setSearchApprovalToDate}  selectedValue={setSearchApprovalFromDate} title={'approvalBdmRequestDate'} />,
    },
    {
      field: "bdmName",
      headerName: "BDM Name",
      width: 150,
      sortable: false,
      renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setSearchApprovalBDMName={setSearchApprovalBDMName} selectedValue={searchApprovalBDMName} title={'approvalBDMName'}
          //  option={AllEMailData.filter(user => user.bdmName)
          //   .map(user => user.bdmName)}/>,
            options={[...new Set(ApprovableData
              .filter(user => user.bdmName)
              .map(user => user.bdmName))]}/>,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} setSearchApprovalCompanyName={setSearchApprovalCompanyName} selectedValue={searchApprovalCompanyName} title={'approvalCompanyName'}/>,

    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      sortable: false,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 180,
      sortable: false,
      renderHeader: (params) => <FilterHeader params={params} setSearchApprovalJobTitle={setSearchApprovalJobTitle} selectedValue={searchApprovalJobTitle} title={'approvaljobTitle'}/>,


    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      width: 180,
      sortable: false,
      renderHeader: (params) => <FilterHeader params={params} setSearchApprovalJobLocation={setSearchApprovalJobLocation} selectedValue={searchApprovalJobLocation} title={'approvaljobLocation'}/>,

    },
    {
      field: "closeReason",
      headerName: "Reason",
      width: 80,
      
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const reason = params.value ? params.value : "--";

        return (
          <Box>
            {reason !== "--" && (
              <IconButton onClick={() => handleClick(reason)}>
                <VisibilityIcon  />
              </IconButton>
            )}
          </Box>
        );
      },
    },

    {
      field: "statusAdvice",
      headerName: "Advice",
      width: 140,
      align: "right",
      headerAlign: "right",
      editable: true,
      type: "singleSelect",
      sortable: false,
      
      valueOptions: ["DNC", "Blocked", "Pending"],
      renderCell: (params) => {
        const value = params.value || "Pending";
        return (
          <Box>
            <Chip
              label={value}
              size="small"
              sx={{ px: 0.5 }}
              color={
                value === "Pending"
                  ? "success"
                  : value === "Blocked"
                  ? "error"
                  : value === "DNC"
                  ? "warning"
                  : value === "Interview"
                  ? "info"
                  : value === "Priority"
                  ? "secondary"
                  : "default"
              }
            />
          </Box>
        );
      },
      renderHeader: (params) => <FilterHeader params={params} type={"multiselect"} setApprovalStatus={setApprovalStatus} selectedValue={searchApprovalStatus} 
      title={'approvalStatus'}
      options={['DNC','Blocked','Pending']}
      />,
    },
  ];

  const StatusAdvice = ["DNC", "Blocked"];

  // Function to filter ApprovableData based on selectedBDMName
  const filterTableByBDM = (bdmName) => {
    if (!bdmName) {
      setFilteredRows([]);
      return;
    }
    const filtered = ApprovableData.filter((row) => row.bdmName === bdmName);
    setFilteredRows(filtered);
  };

  // Handle cell click to set selectedBDMName and filter the table
  const handleCellClick = (params) => {
    const bdmName = params.row.bdmName;
    const id = params.row.id;
    setSelectedId(id);
    setSelectedBDMName(bdmName);
    setExpandedRow(null); // Reset expanded row when changing BDM
    filterTableByBDM(bdmName); // Filter table based on selected BDM
    // Find and set details of the selected row
    const selectedRow = ApprovableData.find((row) => row.id === id);
    setSelectedRowDetails(selectedRow);
  };
  const [openModalExpiryDate,setOpenModalExpiryDate]=useState(false);
  const [selectExpiryDate,setSelectExpiryDate]=useState('6 Months');

  const handleChangeStatusAdvice = (statusAdvice) => {
    const updatedData = ApprovableData.map((data) => {
      if (data.id === selectedRowDetails.id) {
        let updatedRow={}
            if(selectedRowDetails.statusAdvice===statusAdvice)
            {
              statusAdvice=""
              updatedRow = { ...data, statusAdvice }
            }else{
              updatedRow = { ...data, statusAdvice }
            }
        setSelectedRowDetails(updatedRow);
        return updatedRow;
      }
      return data;
    });
    setApprovableData(updatedData);
  };
  const handleChange=()=>{
    console.log('double click')
  }
  const handleChangeExpiry=(event)=>{
    setSelectExpiryDate(event);
  }
  
  const handleCalculateExpiry = (value) => {
    let isoExpiryDate = '';
    let today = new Date();  // Move initialization outside the switch block
  
    switch (value) {
      case '6 Months':
        let expiryDate6Months = new Date(today);
        expiryDate6Months.setMonth(expiryDate6Months.getMonth() + 6);
        isoExpiryDate = expiryDate6Months.toISOString();
        break;
  
      case '3 Months':
        let expiryDate3Months = new Date(today);
        expiryDate3Months.setMonth(expiryDate3Months.getMonth() + 3);
        isoExpiryDate = expiryDate3Months.toISOString();
        break;
  
      case '1 Month':
        let expiryDate1Month = new Date(today);
        expiryDate1Month.setMonth(expiryDate1Month.getMonth() + 1);
        isoExpiryDate = expiryDate1Month.toISOString();
        break;
  
      default:
        console.log('No expiry period selected');
        break;
    }
  
    return isoExpiryDate;
  };
  
  
  const handleApproved = () => {
    const companyToUpdate = ApprovableCompanies.find(
      (row) => row._id === selectedRowDetails.ID
    );
    // let today = new Date();
    // let expiryDate = new Date(today);
    // expiryDate.setMonth(expiryDate.getMonth() + 6);
    // let isoExpiryDate = expiryDate.toISOString();

    if (companyToUpdate) {
      const updatedJobDetails = companyToUpdate.jobDetails.map((job) => ({
        ...job,
        approvalStatus: true,
      }));

      if (companyToUpdate.transFerBdm?.length > 0&&selectedRowDetails.bdmClickStatus==='Transfer') {
        const lastTransferBdm =
          companyToUpdate.transFerBdm[companyToUpdate.transFerBdm.length - 1];
        const newBdmId = lastTransferBdm.newBdm;
        const action = {
          id: selectedRowDetails.ID,
          jobDetails: updatedJobDetails,
          transFerBdm: companyToUpdate.transFerBdm,
          newBdmId: newBdmId,
          approvedBy: storeData._id,
          approvedDate: new Date(),
          client:client,
        };
        dispatch(transferBdmViaApi(action));
      } else {
        const action = {
          id: selectedRowDetails.ID,
          jobDetails: updatedJobDetails,
          status: selectedRowDetails.statusAdvice
            ? selectedRowDetails.statusAdvice !== "No Action"
              ? selectedRowDetails.statusAdvice
              : ""
            : "",
          expiryDate: handleCalculateExpiry(selectExpiryDate),
          approvedBy: storeData._id,
          approvedDate: new Date(),
          client:client,
        };
        dispatch(adminApprovedCompaniesViaApi(action));
      }
      setLoading(true);
      const debouncedSearchNotActiveCompanies = debounce(() => {
        const action={
          client:client
        }
        dispatch(searchNotActiveCompanies(action));
      }, 2000);

      debouncedSearchNotActiveCompanies();
      setSelectedBDMName("");
      setSelectedRowDetails(null);
    }
  };
  const handleSaveExpiry=()=>{
    setOpenModalExpiryDate(!openModalExpiryDate)
    handleApproved()
  }
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);

  const handleShowData = (reason) => {
    if (containsHTML(reason)) {
      return reason;
    } else {
      const htmlReason = convertToHTML(reason);
      return reason;
    }
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Approval Page"}>
          <Box sx={{ pb: 2 }}>
            <Grid container spacing={2}>
              <Grid item sm={9}>
                <MainBackground height="full">
                  <SectionTitle />
                  {loading ? (
                    <>
                      <ShimmerTable row={5} col={5} />
                    </>
                  ) : (
                    <Box>
                      <DataGrid
                        rows={filterSearchData}
                        columns={companyColumn}
                        disableColumnMenu
                        disableColumnSelector
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnResize
                        disableRowSelectionOnClick
                        disableColumnSort
                        onCellClick={handleCellClick}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        hideFooter={filterSearchData.length < 10}
                        pageSizeOptions={[10]}
                        getRowHeight={() => "auto"}
                        autoHeight
                        sx={{
                          "& .MuiDataGrid-cell": {
                            display: "flex",
                            alignItems: "center !important",
                          },
                          [`& .MuiDataGrid-checkboxInput`]: {
                            color: theme.palette.primary.main,
                            p: 0,
                            "&.Mui-checked": {
                              color: theme.palette.primary.dark,
                            },
                          },
                          //Scroller
                          [`& .MuiDataGrid-scrollbar`]: {
                            scrollbarWidth: "thin",
                            scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                          },
                          [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                            {
                              borderRadius: "10px", // Apply border radius
                            },

                          [`& .MuiDataGrid-scrollbar:hover`]: {
                            scrollbarColor: `${theme.palette.primary.dark}`,
                          },
                        }}
                        // sx={{
                        //   [`& .${gridClasses.cell}`]: {
                        //     py: 0,
                        //   },
                        // }}
                        // processRowUpdate={(newRow) => {
                        //   handleStatusChange(newRow.id, newRow.statusAdvice);
                        //   return newRow;
                        // }}
                      />
                    </Box>
                  )}
                </MainBackground>
              </Grid>

              <Grid item sm={3}>
                <MainBackground>
                  <Backgrounds mb={0}>
                    <Box sx={{ height: "100%" }}>
                      {selectedBDMName && selectedBDMName ? (
                        <Box>
                          <DataWidget
                            title={`Total Close request by ${selectedBDMName}`}
                            value={filteredRows.length}
                            compairValue={30}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0.5,
                              py: 2,
                            }}
                          >
                            <ShowData
                              title={"Company Name"}
                              data={
                                selectedRowDetails &&
                                selectedRowDetails.companyName
                              }
                            />
                            <ShowData
                              title={"Job Title"}
                              data={
                                selectedRowDetails &&
                                selectedRowDetails.jobTitle
                              }
                            />

                            <ShowData
                              title={"Job Location"}
                              data={
                                selectedRowDetails &&
                                selectedRowDetails.jobLocation
                              }
                            />
                            {/* <ShowData
                              title={
                                selectedRowDetails?.transFerBdm?.length > 0
                                  ? "Transfer Reason"
                                  : "Close Reason"
                              }
                              data={
                                selectedRowDetails &&
                                selectedRowDetails.closeReason
                              }
                              color={"error"}
                            /> */}
                            <ShowData
                              title={
                                selectedRowDetails?.transFerBdm?.length > 0
                                  ? "Transfer Reason"
                                  : "Close Reason"
                              }
                              data={""}
                            />
                            <Box
                              sx={{
                                maxHeight: 200,
                                overflow: "auto",
                                "&::-webkit-scrollbar": {
                                  width: "5px", // width of the scrollbar
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor: theme.palette.primary[400],
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: theme.palette.primary.light,
                                  borderRadius: 5,
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                  backgroundColor: theme.palette.primary.dark,
                                },
                              }}
                            >
                              <ReactQuill
                                ref={quillRef}
                                theme="snow"
                                value={handleShowData(
                                  selectedRowDetails &&
                                    selectedRowDetails.closeReason
                                )}
                                placeholder="Text Here"
                                modules={{
                                  toolbar: false, // Disable the toolbar
                                }}
                                readOnly={true} // Make the editor read-only
                              />
                            </Box>
                            <ShowData
                              title={"approved Status"}
                              data={
                                selectedRowDetails &&
                                selectedRowDetails.approvalStatus === true
                                  ? "Approved"
                                  : "Not Approved"
                              }
                              color={
                                selectedRowDetails &&
                                selectedRowDetails.approvalStatus === true
                                  ? "success"
                                  : "error"
                              }
                            />
                            {(selectedRowDetails?.transFerBdm?.length > 0&&selectedRowDetails.bdmClickStatus==='Transfer') ? (
                              <></>
                            ) : (
                              <>
                                <Box>
                                  <Box>
                                    <Typography variant="caption">
                                      Status Advice
                                    </Typography>
                                  </Box>
                                  <Box>
                                    {StatusAdvice.map((item, key) => (
                                      <>
                                        <Radio
                                          key={key}
                                          checked={
                                            selectedRowDetails &&
                                            selectedRowDetails.statusAdvice.toLowerCase() ===
                                              item.toLowerCase()
                                          }
                                          value={item}
                                          label={item}
                                          name="radio-buttons"
                                          inputProps={{ "aria-label": "A" }}
                                          onChange={(e) =>
                                            handleChange(
                                              e.target.value
                                            )
                                          }
                                          onClick={(e)=>handleChangeStatusAdvice( e.target.value)}
                                        />
                                        {item}
                                      </>
                                    ))}
                                  </Box>
                                </Box>
                              </>
                            )}

                            

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                gap: 2,
                              }}
                            >
                              <Button>Denied</Button>
                              <Button onClick={() => selectedRowDetails.bdmClickStatus==='Transfer'?handleApproved():selectedRowDetails.statusAdvice?setOpenModalExpiryDate(!openModalExpiryDate):handleApproved()  }>
                                Approve
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box>Select any row</Box>
                      )}
                    </Box>
                  </Backgrounds>
                </MainBackground>
              </Grid>
            </Grid>
          </Box>
          <Modal
            open={closeReasonModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
             sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "calc(100% - 32px)",
              maxWidth:900,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              m:2,
              p: 4,
            }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Reason
              </Typography>

              <Box
                sx={{
                  maxHeight:"70vh",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "5px", // width of the scrollbar
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.primary[400],
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 5,
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={closeReasonContent}
                  placeholder="Paste Email respone/thread here.. "
                  modules={{
                    toolbar: false, // Disable the toolbar
                  }}
                  readOnly={true} // Make the editor read-only
                />
              </Box>

              <Box></Box>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={handleCloseReasonModal} sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
      open={openModalExpiryDate}
      onClose={() => setOpenModalExpiryDate(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: { xs: 1, md: 2 },
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: 1,
            rowGap: 0.5,
            flexWrap: 'wrap',
            mb: 3,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography fontWeight={400}>
            Are you sure you want to change the expiry?
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="dropdown-label">Select an option</InputLabel>
            <Select
              labelId="dropdown-label"
              id="dropdown"
              value={selectExpiryDate}
              label="Select an option"
              onChange={(e) => handleChangeExpiry(e.target.value)}
            >
              <MenuItem value="6 Months">6 Months</MenuItem>
              <MenuItem value="3 Months">3 Months</MenuItem>
              <MenuItem value="1 Month">1 Month</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Divider />
        {/* <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 2 }}>
          <Button
            color="inherit"
            sx={{ textTransform: 'inherit' }}
            onClick={() => setOpenModalExpiryDate(false)}
          >
            Cancel
          </Button>
        </Box> */}
            <Box
              sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
            >
              <Button
                color="inherit"
                sx={{ textTransform: "inherit" }}
                onClick={() => setOpenModalExpiryDate(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "inherit" }}
                onClick={() => handleSaveExpiry()}
              >
                Save
              </Button>
      </Box>
      </Box>
    </Modal>
          <SnackbarComponent />
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};
export default ApprovalStatus;
