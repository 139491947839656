import { SnackbarProvider } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Backgrounds,
  BDMCustomeDateFilter,
  CustomDateFilter,
  CustomSelect,
  DataWidget,
  FilterHeader,
  MainBackground,
  ScrollView,
  SectionTitle,
  SnackbarComponent,
  WidgetMain,
} from "../../Components";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";

import { idID } from "@mui/material/locale";
import theme from "../../Theme";

import { DatePicker } from "@mui/x-date-pickers";
import { blueGrey } from "@mui/material/colors";
import Chart from "react-google-charts";
import { getAllUsers } from "../../store/auth/login/actions";
import {
  bdmReportDateSearchViaApi,
  bdmWeekReportViaApi,
  searchDateBdmCompanyStatusViaApi,
  stopLoadingRedux,
} from "../../store/reports/action";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import ReactQuill from "react-quill";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ShimmerTable } from "react-shimmer-effects";
import companyStatusElement from "../../Components/companyStatusElements/companyStatus";
const ActivitiesCard = () => {
  return (
    <React.Fragment>
      <Card
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 6px 18px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
        }}
      >
        <CardActionArea>
          <CardContent sx={{ py: 1.5 }}>
            <Typography
              gutterBottom
              sx={{
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 1,
              }}
            >
              Activities Title
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              Lizards are a widespread group of squamate reptiles, with over
              6,000 species,
            </Typography>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions
          sx={{ py: 0.1, display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ ml: 0.5 }}>
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.grey[400],
              }}
            >
              Today, 9:15 - 9:30 PM, IST
            </Typography>
          </Box>

          <Button
            size="small"
            color="error"
            variant="text"
            sx={{ textTransform: "inherit" }}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

const BDMDashboard = () => {
  const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked,companyOnProcess } = companyStatusElement;
  const [weekStartDate, setWeekStartDate] = useState("");
  const [weekEndDate, setWeekEndDate] = useState("");
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDb
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  let companyStatus = useSelector((state) => state.Reports?.companyStatus);
  const [loading, setLoading] = useState(true);
  const [weekLoading, setWeekLoading] = useState(true);
  const [cartLoading, setCartLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [searchDashboardCompanyName, setSearchDashboardCompanyName] =
    useState("");
  const [searchDashboardBDMName, setSearchDashboardBDMName] = useState([]);
  const [searchDashboardStatus, setSearchDashboardStatus] = useState([]);

  const optionsDaySelect = ["Today", "Yesterday", "Lastweek", "Custom Date"];
  const [selectDate, setSelectDate] = useState(optionsDaySelect[0]);
  const handleChangeSelectDate = (event) => {
    setSelectDate(event.target.value);
  };
  const bdmReports = useSelector((state) => state.Reports?.bdmReports);
  const bdmWeekReports = useSelector((state) => state.Reports?.bdmWeekReports);

  const dataJobs = [
    {
      id: 1,
      job: {
        Openings: {
          title: "Job Openings",
          value: 27,
        },
        Closed: {
          title: "Job Closed",
          value: 23,
        },
        hold: {
          title: "Job hold",
          value: 23,
        },
        backout: {
          title: "Job Backout",
          value: 23,
        },
        fille: {
          title: "Job Fille",
          value: 21,
        },
        interviewL1: {
          title: "Interview L1",
          value: 12,
        },
        interviewL2: {
          title: "Interview L2",
          value: 12,
        },
        interviewL3: {
          title: "Interview L3",
          value: 12,
        },
        agreement: {
          title: "Agreement",
          value: 12,
        },
        priority: {
          title: "Priority",
          value: 12,
        },
        placed: {
          title: "Placed",
          value: 12,
        },
      },
    },
  ];
  const [searchFromDBMReport, setSearchFromDBMReport] = useState("");
  const [searchToDBMReport, setSearchToDBMReport] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDBMReport("");
    setSearchToDBMReport("");
    setCartLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });
      const [fromDate, toDate] = selectedDates;
      setSearchFromDBMReport(formattedDates[0]);
      setSearchToDBMReport(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        bdmUsers:
          allUsers && allUsers.filter((user) => user._id === storeData._id),
          client:client,
      };
      dispatch(bdmReportDateSearchViaApi(action));
    }
  };
  let BDMUserCompanies = Array.isArray(bdmReports)
    ? bdmReports
        .reduce((acc, companies) => {
          const useraddedBy = companies.addedBy;
          const existingGroupIndex = acc.findIndex(
            (group) => group.useraddedBy === useraddedBy
          );
          if (existingGroupIndex !== -1) {
            acc[existingGroupIndex].companies.push(companies);
          } else {
            acc.push({ useraddedBy, companies: [companies] });
          }
          return acc;
        }, [])
        .map((group) => ({
          addedBy: group.useraddedBy,
          companies: group.companies,
        }))
    : [];

  let BDMReport = BDMUserCompanies.map((companies, index) => {
    let Positive = [];
    let Open = [];
    let Close = [];
    let Hold = [];
    let Backout = [];
    let InterviewL1 = [];
    let InterviewL2 = [];
    let InterviewL3 = [];
    let Priority = [];
    let Placed = [];
    let Agreement = [];
    let Transfer = [];
    let Shared = [];
    let Accepted = [];
    let Reject = [];

    if (companies && Array.isArray(companies.companies)) {
      companies.companies.forEach((company) => {
        if (company.transFerBdm) {
          Transfer.push(company);
        } else if (company.jobDetails && Array.isArray(company.jobDetails)) {
          company.jobDetails.forEach((job) => {
            switch (job.status) {
              case "Positive":
              case "Active":
                Positive.push(company);
                break;
              case "Open":
                Open.push(company);
                break;
              case "Close":
                Close.push(company);
                break;
              case "Hold":
                Hold.push(company);
                break;
              case "Backout":
                Backout.push(company);
                break;
              case "Interview L1":
                InterviewL1.push(company);
                break;
              case "Interview L2":
                InterviewL2.push(company);
                break;
              case "Interview L3":
                InterviewL3.push(company);
                break;
              case "Priority":
                Priority.push(company);
                break;
              case "Placed":
                Placed.push(company);
                break;
              case "Agreement":
                Agreement.push(company);
                break;
              case "Shared":
                Shared.push(company);
                break;
              case "Accepted":
                Accepted.push(company);
                break;
              case "Reject":
                Reject.push(company);
                break;
            }
          });
        }
      });
    }

    return {
      id: index + 1, // Corrected to use the index properly
      Positive: Positive,
      Open: Open,
      Close: Close,
      Hold: Hold,
      Backout: Backout,
      InterviewL1: InterviewL1,
      InterviewL2: InterviewL2,
      InterviewL3: InterviewL3,
      Priority: Priority,
      Placed: Placed,
      Agreement: Agreement,
      Transfer: Transfer,
      Shared: Shared,
      Accepted: Accepted,
      Reject: Reject,
    };
  });
  //////////////
  function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = dayjs(startDate);

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      dates.push(currentDate.format("DD-MM-YYYY"));
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  }
  const betweenDates = getDatesBetween(weekStartDate, weekEndDate);
  const matchingDateWiseData = (data, dates) => {
    if (!Array.isArray(data)||!Array.isArray(dates)) {
    
      return [];
    }
    const setData = [
      [
        "Date",
        "Positive",
        "Open",
        "Close",
        "Hold",
        "Blocked",
        "InterviewL1",
        "InterviewL2",
        "InterviewL3",
        "Priority",
        "Placed",
        "Agreement",
        "Transfer",
        "Accepted",
        "Reject",
      ],
    ];

    function formatDateWithDay(dateString) {
      const formattedDate = dayjs(dateString, "DD-MM-YYYY").format(
        "DD/MM/YYYY"
      );
      const dayName = dayjs(dateString, "DD-MM-YYYY").format("dd"); // 'dd' gives the two-letter day name
      return `${formattedDate}/${dayName}`;
    }

    dates.forEach((date) => {
      // Initialize arrays for each category
      const Transfer = [];
      const Positive = [];
      const Open = [];
      const Close = [];
      const Hold = [];
      const Blocked = [];
      const InterviewL1 = [];
      const InterviewL2 = [];
      const InterviewL3 = [];
      const Priority = [];
      const Placed = [];
      const Agreement = [];
      const Accepted = [];
      const Reject = [];

      // Process each company data
      data.forEach((company) => {
        const addedOnDate = dayjs(company.addedOn)
          .tz("America/New_York")
          .format("DD-MM-YYYY");
        if (date === addedOnDate) {
          if (company.transFerBdm) {
            Transfer.push(company);
          } else if (company.jobDetails && Array.isArray(company.jobDetails)) {
            company.jobDetails.forEach((job) => {
              switch (job.status) {
                case "Positive":
                case "Active":
                  Positive.push(company);
                  break;
                case "Open":
                  Open.push(company);
                  break;
                case "Close":
                  Close.push(company);
                  break;
                case "Hold":
                  Hold.push(company);
                  break;
                case "Blocked":
                  Blocked.push(company);
                  break;
                case "Interview L1":
                  InterviewL1.push(company);
                  break;
                case "Interview L2":
                  InterviewL2.push(company);
                  break;
                case "Interview L3":
                  InterviewL3.push(company);
                  break;
                case "Priority":
                  Priority.push(company);
                  break;
                case "Placed":
                  Placed.push(company);
                  break;
                case "Agreement":
                  Agreement.push(company);
                  break;
                case "Accepted":
                  Accepted.push(company);
                  break;
                case "Reject":
                  Reject.push(company);
                  break;
              }
            });
          }
        }
      });

      // Format the date with the day of the week
      const formattedDate = formatDateWithDay(date);

      // Push the result to setData
      setData.push([
        formattedDate,
        Positive.length,
        Open.length,
        Close.length,
        Hold.length,
        Blocked.length,
        InterviewL1.length,
        InterviewL2.length,
        InterviewL3.length,
        Priority.length,
        Placed.length,
        Agreement.length,
        Transfer.length,
        Accepted.length,
        Reject.length,
      ]);
    });

    return setData;
  };
  const takeDatewiseBDMData = matchingDateWiseData(
    bdmWeekReports,
    betweenDates
  );


  const options = {
    //title: "Monthly Coffee Production by Country",
    vAxis: {
      //title: "Cups",
    },
    hAxis: {
      //title: "Month",
      titleTextStyle: {
        fontSize: 18, // Adjust the font size as needed
        color: theme.palette.primary.main, // Adjust the color as needed
        italic: false, // Ensure italic font style is disabled
        bold: true, // Optionally bold the title
      },
      textStyle: {
        fontSize: 12,
        slantedText: true,
        slantedTextAngle: 90,
      },
    },
    seriesType: "bars",
    series: { 5: { type: "line" } },
    chartArea: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 40,
    },
  };

  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );
  const [searchFromDateBDM, setSearchFromDateBDM] = useState("");
  const [startingDateBDM, setStartingDateBDM] = useState(
    dayjs().tz("America/New_York").startOf("day")
  );
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);

  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const [closeReasonContent, setCloseReasonContent] = useState("");
  const [closeReasonModal, setCloseReasonModal] = useState(false);
  const quillRef = useRef(null);
  const handleCloseReasonModal = () => {
    setCloseReasonModal(false);
    setCloseReasonContent("");
  };
  const containsHTML = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    return Array.from(div.childNodes).some((node) => node.nodeType === 1); // Check if any child node is an element
  };
  const convertToHTML = (text) => {
    return `<p>${text}</p>`;
  };
  const handleClick = (reason) => {
    if (containsHTML(reason)) {
      setCloseReasonContent(reason);
      setCloseReasonModal(true);
    } else {
      const htmlReason = convertToHTML(reason);
      setCloseReasonContent(htmlReason);
      setCloseReasonModal(true);
    }
  };
  const BdmStatusColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "addedOn",
      headerName: "Date",
      width: 250,
      valueGetter: (params) => {
        if (params) {
          return formatDateForDisplay(params);
        } else {
          return "--";
        }
      },
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          setSearchDashboardCompanyName={setSearchDashboardCompanyName}
          selectedValue={searchDashboardCompanyName}
          title={"dashBoardCompanyName"}
        />
      ),
    },
    {
      field: "addedBy",
      headerName: "BDM Name",
      width: 180,
      flex: 1,
      valueGetter: (params) => {
        if (params) {
          const user = allUsers.find((user) => user._id === params);
          return user ? user.userFirstName : "--";
        } else {
          return "--";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const { row } = params;
        if (row) {
          let status = row.status;
          if (
            row.jobDetails &&
            row.jobDetails.length > 0 &&
            status !== "DNC" &&
            status !== "Blocked" &&
            status !== "No FollowUp"
          ) {
            const company = row.jobDetails[0];
            status = company.status === "Active" ? "Open" : company.status;
          }
          return (
            <Box>
              <Chip
                label={status}
                size="small"
                sx={{ px: 0.5 }}
                color={
                  status === "Positive"
                    ? "success"
                    : status === "Open"
                    ? "success"
                    : ["Blocked", "Close"].includes(status)
                    ? "error"
                    : ["DNC", "No FallowUp"].includes(status)
                    ? "warning"
                    : ["Interview L1", "Interview L2", "Interview L3"].includes(
                        status
                      )
                    ? "info"
                    : status === "Priority"
                    ? "secondary"
                    : "default"
                }
              />
            </Box>
          );
        }
        return "";
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="multiselect"
          setSearchDashboardStatus={setSearchDashboardStatus}
          selectedValue={searchDashboardStatus}
          title={"dashboardStatus"}
          options={[
            "Positive",
            "Open",
            "DNC",
            "No FallowUp",
            "Close",
            "Blocked",
            "Hold",
            "Interview L1",
            "Interview L2",
            "Interview L3",
          ]}
        />
      ),
    },
    {
      field: "jobDetails",
      headerName: "Reason",
      width: 80,
      renderCell: (params) => {
        const jobDetails =
          params.row && params.row.jobDetails ? params.row.jobDetails : [];
        const reason =
          jobDetails.length > 0 ? jobDetails[0].closeReason || "--" : "--";

        return (
          <Box>
            {reason !== "--" && (
              <IconButton onClick={() => handleClick(reason)}>
                <VisibilityIcon style={{ marginRight: 5 }} />
              </IconButton>
            )}
          </Box>
        );
      },
      // valueGetter: (params) => {
      //   if (params && params.length > 0) {
      //     return params[0].closeReason;
      //   } else {
      //     return "--";
      //   }
      // },
    },
  ];

  const [searchFromDateBDMStatus, setSearchFromDateBDMStatus] = useState("");
  const [searchToDateBDMStatus, setSearchToDateBDMStatus] = useState("");
  const handleDateChangeBDMCompanyStatus = (selectedDates) => {
    setSearchFromDateBDMStatus("");
    setSearchToDateBDMStatus("");
    setLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });
      const [fromDate, toDate] = selectedDates;
      setSearchFromDateBDMStatus(formattedDates[0]);
      setSearchToDateBDMStatus(formattedDates[1]);

      // const action = {
      //   searchFromDate: formattedDates[0],
      //   searchToDate: formattedDates[1],
      // };
      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        bdmUsers:
          allUsers && allUsers.filter((user) => user._id === storeData._id),
          client:client,
      };
      dispatch(searchDateBdmCompanyStatusViaApi(action));
    }
  };
  // const [selectBdm,setSelectBdm]=useState({userFirstName:'All',addedBy:'1234567'});
  const [selectBdm, setSelectBdm] = useState("all");
  const [selectBdmId, setSelectBdmId] = useState([]);
  // companyStatus = companyStatus.filter(
  //   (company) => selectBdmId.includes(company.addedBy)
  // );
  const filteredCompanyStatus =
    selectBdm === "all"
      ? companyStatus
      : companyStatus.filter((company) => company.addedBy === selectBdm);
  const filterDNCData = Array.isArray(filteredCompanyStatus)
    ? filteredCompanyStatus
        .filter((company) => company.status === "DNC")
        .map((company, index) => ({
          ...company,
          id: index + 1,
        }))
    : [];

  const companiesListWithStatus = Array.isArray(companyStatus)
    ? companyStatus.map((company, index) => {
        const user = allUsers.find((user) => user._id === company.addedBy);
        return {
          ...company,
          bdmName: user ? user.userSudoFirstName : "--",
          id: index + 1,
        };
      })
    : [];

  const filterCompaniesListWithStatus = Array.isArray(companiesListWithStatus)
    ? companiesListWithStatus.filter((data) => {
        // Convert necessary fields to lower case for case-insensitive comparison
        const lowerCaseCompanyName = data.companyName?.toLowerCase() || "";
        const lowerBdmName = data.bdmName || ""; // Convert to lower case
        let lowerStatus = data.status || ""; // Convert to lower case

        if (lowerStatus === companyOnProcess) {
          const jobStatus = data.jobDetails?.[0]?.status; // Get job status
          lowerStatus = jobStatus === "Active" ? "Positive" : jobStatus || "";
        }
        // Search term inputs
        const SearchDashboardCompanyName =
          searchDashboardCompanyName?.toLowerCase() || ""; // Convert to lower case
        const SearchDashboardBDMName = searchDashboardBDMName || [];
        const SearchDashboardStatus = searchDashboardStatus || [];

        // Filter logic
        return (
          lowerCaseCompanyName.includes(SearchDashboardCompanyName) &&
          (SearchDashboardBDMName.length === 0 ||
            SearchDashboardBDMName.includes(lowerBdmName)) &&
          (SearchDashboardStatus.length === 0 ||
            SearchDashboardStatus.includes(lowerStatus))
        );
      })
    : [];
    
  const weekDataReports = () => {
    setWeekLoading(true);
    // const currentDate = dayjs();
    const weekStart = dayjs().tz("America/New_York").startOf("week").add(1, 'day').startOf("day"); // Monday
    const weekEnd = dayjs().tz("America/New_York").endOf("week").subtract(1, 'day').endOf("day"); // Friday

    setWeekStartDate(weekStart);
    setWeekEndDate(weekEnd);

    const action = {
      searchFromDate: weekStart,
      searchToDate: weekEnd,
      bdmUsers:
        allUsers && allUsers.filter((user) => user._id === storeData._id),
        client:client,
    };
  
    dispatch(bdmWeekReportViaApi(action));
  };

  useEffect(() => {
    handleDateChangeBDMCompanyStatus([
      dayjs().tz("America/New_York").startOf("day"),
      dayjs().tz("America/New_York").endOf("day"),
    ]);
    handleDateChange([
      dayjs("2024-01-01").tz("America/New_York").startOf("day"),
      dayjs().tz("America/New_York").endOf("day"),
    ]);
    weekDataReports();
  }, []);

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      setCartLoading(false);
      setWeekLoading(false);
      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
        setCartLoading(false);
        setWeekLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle="BDM Dashboard">
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Box sx={{ mb: 1 }}>
                <BDMCustomeDateFilter
                  handleDateChange={handleDateChange}
                  tillDate={"Till Date"}
                />
              </Box>
              <Box>
                {cartLoading ? (
                  <>
                    <ShimmerTable row={5} col={5} />
                  </>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <MainBackground height="full">
                        <SectionTitle title="Job" size="sm" />
                        <DataWidget
                          title="Postive"
                          value={BDMReport && BDMReport[0]?.Positive.length}
                          type="line"
                        />
                        <DataWidget
                          title="Open"
                          value={BDMReport && BDMReport[0]?.Open.length}
                          type="line"
                        />
                        <DataWidget
                          title="Closed"
                          value={BDMReport && BDMReport[0]?.Close.length}
                          type="line"
                        />
                        <DataWidget
                          title="Hold"
                          value={BDMReport && BDMReport[0]?.Hold.length}
                          type="line"
                        />
                      </MainBackground>
                    </Grid>
                    <Grid item xs={3}>
                      <MainBackground height="full">
                        <SectionTitle title="Agreement" size="sm" />
                        <DataWidget
                          title="Shared"
                          value={BDMReport && BDMReport[0]?.Transfer.length}
                          type="line"
                        />
                        <DataWidget
                          title="Hold"
                          value={BDMReport && BDMReport[0]?.Hold.length}
                          type="line"
                        />
                        <DataWidget
                          title="Accepted"
                          value={BDMReport && BDMReport[0]?.Accepted.length}
                          type="line"
                        />
                      </MainBackground>
                    </Grid>
                    <Grid item xs={3}>
                      <MainBackground height="full">
                        <SectionTitle title="Interview" size="sm" />
                        <DataWidget
                          title="L1"
                          value={BDMReport && BDMReport[0]?.InterviewL1.length}
                          type="line"
                        />
                        <DataWidget
                          title="L2"
                          value={BDMReport && BDMReport[0]?.InterviewL2.length}
                          type="line"
                        />
                        <DataWidget
                          title="L3"
                          value={BDMReport && BDMReport[0]?.InterviewL3.length}
                          type="line"
                        />
                      </MainBackground>
                    </Grid>
                    <Grid item xs={3}>
                      <MainBackground height="full">
                        <SectionTitle title="Candidate" size="sm" />
                        <DataWidget
                          title="Reject"
                          value={BDMReport && BDMReport[0]?.Reject.length}
                          type="line"
                        />
                        <DataWidget
                          title="Placed"
                          value={BDMReport && BDMReport[0]?.Placed.length}
                          type="line"
                        />
                        <DataWidget
                          title="Backout"
                          value={BDMReport && BDMReport[0]?.Backout.length}
                          type="line"
                        />
                      </MainBackground>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box mt={2}>
                {weekLoading ? (
                  <>
                    <ShimmerTable row={5} col={5} />
                  </>
                ) : (
                  <MainBackground>
                    <SectionTitle title={"This Week Report"} size="sm" />
                    <Chart
                      chartType="ComboChart"
                      width="100%"
                      height="345px"
                      data={takeDatewiseBDMData}
                      options={options}
                    />
                  </MainBackground>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <MainBackground>
                <Box>
                  <SectionTitle title="Upcoming Activities" />
                </Box>
                <ScrollView>
                  <Box
                    sx={{
                      maxHeight: 680,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      {Object.entries(dataJobs[0].job).map(
                        ([key, { title, value }]) => (
                          <ActivitiesCard />
                        )
                      )}{" "}
                    </Box>
                  </Box>
                </ScrollView>
              </MainBackground>
            </Grid>
          </Grid>
          <Box py={2} />
          <Grid container>
            <Grid item xs={12}>
              <MainBackground>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "self-end",
                  }}
                >
                  <Box>
                    <SectionTitle title="Company Status" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "self-end",
                      gap: 1,
                      flex: 1,
                    }}
                  >
                    <Box flex={1}>
                      <BDMCustomeDateFilter
                        handleDateChange={handleDateChangeBDMCompanyStatus}
                      />
                    </Box>
                  </Box>
                </Box>

                {loading ? (
                  <>
                    {/* <ShimmerTable row={5} col={5} /> */}
                    <ShimmerTable row={5} col={5} />
                  </>
                ) : (
                  <>
                    <DataGrid
                      rows={filterCompaniesListWithStatus}
                      columns={BdmStatusColumns}
                      disableColumnMenu
                      disableColumnSorting
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnReorder
                      disableColumnResize
                      hideFooter={filterCompaniesListWithStatus.length < 5}
                      disableColumnSort
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 100 },
                        },
                      }}
                      pageSizeOptions={[100]}
                      getRowHeight={() => "auto"}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-cell": {
                          display: "flex",
                          alignItems: "center !important",
                        },
                        [`& .MuiDataGrid-checkboxInput`]: {
                          color: theme.palette.primary.main,
                          p: 0,

                          "&.Mui-checked": {
                            color: theme.palette.primary.dark,
                          },
                        },
                        //Scroller
                        [`& .MuiDataGrid-scrollbar`]: {
                          scrollbarWidth: "thin",
                          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                        },
                        [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                          borderRadius: "10px", // Apply border radius
                        },

                        [`& .MuiDataGrid-scrollbar:hover`]: {
                          scrollbarColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    />
                  </>
                )}
              </MainBackground>
            </Grid>
          </Grid>
          <SnackbarComponent/>
        </LayoutSidebarMenu>
      </SnackbarProvider>
      <Modal
        open={closeReasonModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 32px)",
            maxWidth: 900,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            m: 2,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reason for Closing
          </Typography>

          <Box
            sx={{
              maxHeight: "70vh",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px", // width of the scrollbar
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.primary[400],
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: 5,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={closeReasonContent}
              placeholder="Paste Email respone/thread here.. "
              modules={{
                toolbar: false, // Disable the toolbar
              }}
              readOnly={true} // Make the editor read-only
            />
          </Box>

          <Box></Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleCloseReasonModal} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default BDMDashboard;
